//////////////
/// COLORS ///
//////////////

$white: #fff;
$dark-grey: #282828;
$black: #010101;
$focus-blue: #20b4d6;
$action-green: #1aaf48;
$action-red: #d23b39;
$videoGreen: #47d95a;
$selected-grey: #808080;
$aspectRatio: 16 / 9;
$barnes-orange: #d64220;

$themes: (
  default: (
    color__copy: #010101,
    color__contrast: #fff,
    color__grey: #dcdcdc,
    color__grey--opacity: rgba(220, 220, 220, 0.5),
    color__error: #ff0000,
    color__brand--light: #fa4c25,
    color__brand--medium: #d64220,
    color__brand--dark: #b3371b,
    color__brand--hover: #e68e79,
    color__brand--contrast: #fff,
    color__copy--light: #8c8c8c,
    color__copy--medium: #6e6e6e,
    color__copy--dark: #282828,
    color__copy--aws: #3f4149,
    color__copy--label: #6e6e6e,
    color__link: #0078D4,
    // for modals
    color__background--light: #fff,
    color__background--shadow: #010101,
    // for inputs
    color__background--medium: #d9d9d9,
    // for backgrounds
    color__background--dark: #f7f7f7,
    color__background--grey: #dcdcdc,
    // TODO: add ability to change from white to black for instructors
    color__background--canvas: #fff,
    color__button: #f7f7f7,
    color__button--hover: #fff,
    color__button--border: #dcdcdc,
    color__border--dark: #b4b4b4,
    color__border--input: #dcdcdc,
    color__button--calendar: #f7f7f7,
    color__nav--hover: #e68e79,
    color__video--icons: #fff,
  ),
  dark: (
    color__copy: #fff,
    color__contrast: #010101,
    color__grey: #5e5e5e,
    color__grey--opacity: rgba(94, 94, 94, 0.5),
    color__error: #ff0000,
    color__brand--light: #fa4c25,
    color__brand--medium: #d64220,
    color__brand--dark: #b3371b,
    color__brand--hover: #e68e79,
    color__brand--contrast: #fff,
    color__copy--light: #fff,
    color__copy--medium: #6e6e6e,
    color__copy--dark: #dcdcdc,
    color__copy--aws: #fff,
    color__copy--label: #fff,
    color__link: #20b4d6,
    // for modals
    color__background--light: #272727,
    color__background--shadow: #010101,
    // for inputs
    color__background--medium: #404040,
    color__background--dark: #1f1f1f,

    color__background--grey: #1f1f1f,
    // for backgrounds
    color__background--canvas: #010101,
    color__button: #f7f7f7,
    color__button--hover: #fff,
    color__button--border: #dcdcdc,
    color__border--dark: #b4b4b4,
    color__border--input: #fff,
    color__button--calendar: #010101,
    color__nav--hover: #f7f7f7,
    color__video--icons: #282828,
  ),
  // TODO: create high contrast theme
);

// added per secondary palette in Barnes Branding Guidelines Document
$color__admin-blue: #004c97;
$color__admin-blue--light: #6cace4;
$color__green: #8e9a36;
$color__yellow: #fdd824;

$color__contrast: #fff;
$color__grey: #dcdcdc;

$color__copy: #010101;
$color__copy--light: #8c8c8c;
$color__copy--medium: #6e6e6e;
$color__copy--dark: #282828;

$color__copy--label: #282828;
$color__background--light: #fff;
$color__background--medium: #d9d9d9;
$color__background--dark: #f7f7f7;
$color__background--grey: #dcdcdc;
// TODO: remove when themes are added
$color__background--canvas: #010101;

$color__button: $color__background--dark;
$color__button--border: #dcdcdc;
$color__button--calendar: $color__background--dark;
$color__border--dark: #b4b4b4;

$color__nav--hover: #e68e79;

/////////////
/// FONTS ///
/////////////
$font-family__primary: Calibre, sans-serif;
$font-family__secondary: FFMiloSerifWebText, serif;

/////////////////
/// FONT SIZE ///
/////////////////
$font-size__massive--mobile: 40px;
$font-size__massive--desktop: 42px;
$font-size__header--mobile: 32px;
$font-size__header--desktop: 38px;
$font-size__sub-header: 25px;
$font-size__button: 19px;
$font-size__body: 18px;
$font-size__chat: 18px;

///////////////
// Z-INDEXES //
///////////////
$z-indexes: (
  content: 1,
  icon: 1,
  button: 2,
  spotlight: 9,
  engagement: 10,
  toolBar: 11,
  container: 11,
  meeting: 11,
  modal-overlay: 12,
  modal: 13,
  video: 13,
  more-actions: 14,
  tool-tips: 15,
);
$z-index-art-object-grid-overlay: 2;
$z-index-art-object-grid-view-more: 2;
$z-index-loading-overlay: 2;

////////////////////
// HEADER HEIGHTS //
////////////////////
$headerHeights: (
  xxlarge: 130px,
  xlarge: 130px,
  large: 110px,
  medium: 100px,
  small: 70px,
  xsmall: 70px,
);

////////////////////
/// PAGE PADDING ///
////////////////////
$pagePadding: (
  xxlarge: 110px,
  xlarge: 100px,
  large: 80px,
  medium: 50px,
  small: 40px,
  xsmall: 25px,
);

// art object grid
$art-object-grid-gap: 4px;

// chat message details
$chat-message-gap: 0.25rem;

///////////////////////////
/// COMPONENT CONSTANTS ///
///////////////////////////
$toolbar-height: 48px;
$divider-height: 37px;
$mobile-toolbar-height: 80px;
$mobile-replay-toolbar-height: 120px;
$mobile-portrait-toolbar-expanded-height: 70vh;
$mobile-landscape-toolbar-expanded-height: 80vh;
$chapter-marker-preview-width: 180px;
$icon-button__tool-bar: 40px;
$icon-button__slide-preview: 18px;
$attendee-initials-height: 2rem;

///////////////////
/// BREAKPOINTS ///
///////////////////
// Breakpoint information, where each starts and stops
// if a breakpoint is not fluid, then the start value is equal to the main col value plus 2x the gutter at this breakpoint
$breakpoints: (
  xsmall: (
    start: 0,
    end: 413,
  ),
  small: (
    start: 414,
    end: 649,
  ),
  medium: (
    start: 650,
    end: 989,
  ),
  large: (
    start: 990,
    end: 1199,
  ),
  xlarge: (
    start: 1200,
    end: 1599,
  ),
  xxlarge: (
    start: 1600,
    end: null,
  ),
);

//////////////////////////
/// EXPORTED VARIABLES ///
//////////////////////////
// Any variables added here will be available in our JS code via webpack
// See more info see https://css-tricks.com/getting-javascript-to-talk-to-css-and-sass/
:export {
  barnesOrange: $barnes-orange;
  actionGreen: $action-green;
  actionRed: $action-red;
  darkGrey: $dark-grey;
  white: $white;
  colorYellow: $color__yellow;
  adminBlue: $color__admin-blue--light;
  selectedGrey: $selected-grey;
  colorCopy: $color__copy;
  aspectRatio: $aspectRatio;
  toolBarHeight: $toolbar-height;
  dividerHeight: $divider-height;
  mobileToolbarHeight: $mobile-toolbar-height;
  mobileReplayToolbarHeight: $mobile-replay-toolbar-height;
  mobilePortraitToolbarExpandedHeight: $mobile-portrait-toolbar-expanded-height;
  mobileLandscapeToolbarExpandedHeight: $mobile-landscape-toolbar-expanded-height;
  chapterMarkerPreviewWidth: $chapter-marker-preview-width;

  // Automatically add all variables set in the themes, these will be un-nested
  // and set with the key `<theme-name>_<nested-variable-name>`
  @each $key, $value in $themes {
    @each $k, $v in $value {
      #{unquote($key)}_#{unquote($k)}: $v;
    }
  }

  // Automatically add all breakpoint variables, these will be un-nested
  // and set with the key `breakpoint_<size>_<position>`
  @each $key, $value in $breakpoints {
    @each $k, $v in $value {
      breakpoint_#{unquote($key)}_#{unquote($k)}: $v;
    }
  }
}