/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .replay__explore-focus--following {
      background-color: getThemeValue("color__background--light");
    }

    .replay__explore-focus--following-text, .replay__explore-focus--following-text.mobile-tools, .replay__explore-focus--following-text.mobile-tools.minimized-indicator {
      color: getThemeValue("color__copy");
      &--strong {
        text-shadow: 0.5px 0 currentColor;
      }
    }
  }
}

//////////////
/// REPLAY ///
//////////////
.replay {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
  
 
  &__explore-focus {
    z-index: map-get($z-indexes, "tool-tips");
    margin-top: -1rem;
    display: flex;
    
    &.minimized-indicator{
      left: unset;
      right: 56px;
      top: 24px;
      z-index: map-get($z-indexes, "tool-tips");
    }
    &.mobile-tools{
      top: 20px !important;
      right: 52px !important;
      z-index: map-get($z-indexes, "tool-tips");
    }

    @include breakpoint("xsmall+") {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 24px;
      border-radius: 6px;
    }

    .toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .toggle-container .toggle .toggle-inner {
      overflow: visible;
    }
  }
  &__explore-focus-helper--container {
    position: absolute;
    height: 0;
    width: 0;
    margin: 0;
    transform: translate(-50%, 0);
    top: 50px
  }
  &__explore-focus.mobile-tools {
    width: max-content;
    .toggle-container.mobile-tools {
        display: initial;
    }
  }
  &__explore-focus-helper--container {
    position: absolute;
    height: 0;
    width: 0;
    margin: 0;
    left: 50%;
    transform: translate(-50%, 0);
    top: 50px
  }

  // set the desktop chat to be on the right side
 &__chapter-list-container{
    position: absolute;
    right: 0.5rem;
    top: calc(35vh - 100px);
  }

  .icon-tool__inner {
    @include transition();

    &.show {
      opacity: 1;
    }

    &.hide {
      opacity: 0;;
    }
  }
}

@include breakpoint("medium+") {
  .replay {
    .chat-container__chat-content {
      width: 16rem;
    }
  }
}

.replay__explore-focus--following {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 8px;
}
.replay__explore-focus--following-text, .replay__explore-focus--following-text.mobile-tools, .replay__explore-focus--following-text.mobile-tools.minimized-indicator {
  span {
    width: 100%;
    text-align: center;
  }
  text-align: center;
  border-radius: 6px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  &--strong {
    font-size: 1.1rem;
    letter-spacing: 0.5px;
    font-weight: bold;
  }
}