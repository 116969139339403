///////////////
//// THEME ////
///////////////
.theme {
  @include theme() {
    .mobile-tools {
      .tool-divider {
        border-right: 1px solid getThemeValue("color__grey");
      }

      &__chat {
        .container.active {
          box-shadow: none;

          .chat-container__simplebar {
            background-color: getThemeValue("color__background--dark");
          }
        }
      }

      .more-actions-popup-container {
        .__selected-speed {
          border: 2px solid getThemeValue("color__brand--medium");
          background-color: getThemeValue("color__brand--medium");
          color: getThemeValue("color__background--light");
        }
      }
    }

    .mobile-tools-wrapper {

      &.--landscape,
      &.--portrait {
        color: getThemeValue("color__copy");
        background-color: getThemeValue("color__background--light");
      }
    }
  }
}

///////////////////////
/// COMPONENT STYLE ///
///////////////////////
.mobile-tools-wrapper {
  .mobile-tools {
    z-index: map-get($z-indexes, "toolBar");
    position: relative;
    transition: all 1s ease 0.1s;
    left: 50% !important;
    transform: translate(-50%, 0);
    justify-content: space-between;

    .children.horizontal.active {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 98%;
      align-items: center;
      justify-content: flex-start;

      .tool-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &#swipe-grip {
          justify-content: center;
          height: 20px;
        }

        ;

        .progress-bar-wrapper {
          width: 100%;
        }
      }
    }

    .more-actions-popup-container {
      position: absolute;
      left: -124px;
      top: 0;
      width: fit-content;
      height: auto;
      transition: opacity 0.5s ease-in-out;
      transition-delay: 1.3s;
      opacity: 1;

      @include breakpoint("large+") {
        // top: -37px;
      }

      .children {
        height: fit-content !important;

      }

      &.hidden {
        opacity: 0;
      }

      .btn-secondary {
        margin: 5px;
      }
    }
  }

  &.--landscape,
  &.--portrait {
    transition: color 0.25s, background-color 0.25s ease 0.1s;
  }
}

#mobile-tools__more-actions__captions {
  .icon-button-wrapper {
    height: 32px;

    .icon-button {
      height: 100%;
    }

    .icon {
      height: auto;
    }
  }
}

/////////////////////
//// BREAKPOINTS ////
/////////////////////
@include breakpoint("xsmall+") {
  .mobile-tools__chat.--portrait {
    width: 100%;

    .container.chat-container {
      width: 92vw;
      overflow-x: hidden;
      height: auto;
      max-width: 450px;
      margin: auto;
    }
  }
}

@include breakpoint("medium+") {
  .mobile-tools__chat.--landscape {
    .container.chat-container {
      width: calc(43vw + 48px);
    }
  }
}

//////////////////////
//// MOBILE TOOLS ////
//////////////////////

.mobile-tools-wrapper {
  z-index: map-get($z-indexes, "tool-tips");
  transition: 0.25s all;
  -webkit-transition: 0.25s all;
  border-radius: 4px 4px 0 0 !important;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  .more-actions-container {
    height: -moz-available;
    /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    height: fill-available;
    height: fit-content;
    padding: 10px;
    margin-top: -2.5rem;
  }

  ///////////////////////////////
  //// PORTRAIT MOBILE TOOLS ////
  ///////////////////////////////

  &.--portrait {
    .mobile-tools {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100vw;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      border-radius: 4px 4px 0 0 !important;

      .children {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 600px;
        height: 100%;

        &.horizontal {
          svg {
            width: initial;
          }
        }
      }

      .focused,
      .focused-dark,
      .exploring,
      .exploring-dark {
        width: 100px;

        svg {
          width: 100% !important;
        }
      }

      .children {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 600px;

      }

      .more-actions-container-children {
        height: auto;
      }

      &__inner {
        margin-top: 60px;
        width: 100%;
        height: calc(100% - 60px);
        display: flex;

        &.--replay {
          margin-top: 0;
          height: auto;
          display: flex;
          flex-direction: column;
        }

        .videos__user {
          position: absolute;
          height: 100px;
          width: 165px;
          box-shadow: none;
          border-radius: 6px;
          padding: 4px;
          margin: unset;
          bottom: 8px;
          right: 8px;
          transform: unset;
        }
      }

      &__videos {
        z-index: map-get($z-indexes, "toolBar");
        height: 100%;
        width: 100%;

        .student__wrapper {
          box-shadow: none;
          width: 100%;
        }

        .student-videos__container {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding-left: 16px;
          padding: 8px;
          gap: 8px;
          width: 100%;
        }
      }
    }
  }

  .mobile-tools.horizontal {
    .icon-button-wrapper {
      align-items: initial;
      justify-content: initial;
    }
  }

  ////////////////////////////////
  //// LANDSCAPE MOBILE TOOLS ////
  ////////////////////////////////

  &.--landscape {
    .mobile-tools {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      border-radius: 4px 4px 0 0 !important;
      height: 100%;

      .focused,
      .focused-dark,
      .exploring,
      .exploring-dark {
        width: 100px;

        svg {
          width: 100% !important;
        }
      }

      .children {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 600px;

      }

      .more-actions-container-children {
        height: auto;
      }

      .icon-button {
        width: 12%; // change based upon number of icons in toolbar
        margin-left: -1px;
        margin-right: -1px;
      }

      &__inner {
        margin-top: 60px;
        width: 100%;
        display: flex;

        &.--replay {
          margin-top: 0;
        }

        .videos__user {
          position: absolute;
          height: 100px;
          width: 165px;
          box-shadow: none;
          border-radius: 6px;
          padding: 4px;
          margin: unset;
          bottom: 4px;
          right: 50%;
          transform: unset;
        }
      }

      &__videos {
        z-index: map-get($z-indexes, "toolBar");
        height: 100%;
        width: 50%;

        .student__wrapper {
          box-shadow: none;
          width: calc(100% - 174px);
          margin-left: 6px;
        }

        .student-videos__container {
          display: flex;
          flex-wrap: none;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding-left: 16px;
          padding: 8px;
          gap: 8px;
          width: 100%;
        }
      }
    }
  }
}

.mode-mobile-wrapper {
  position: absolute;
  top: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .mode-mobile {
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 48px;
    max-width: 144px;

    p {
      margin: 0;
      padding: 0;
      font-weight: 800;
      font-size: 16px;
    }

    .children {
      width: 100%;

      .injected-svg {
        width: 100%;
      }
    }

    .close__wrapper {
      display: none;
      visibility: hidden;
    }
  }
}