.canvas {
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  background-color: $color__background--canvas;

  &__board-viewer {
    display: flex;
    flex-direction: column;
  }

  &__caption {
    position: fixed;
    bottom: 0;
    left: 0;
    color: $color__contrast;
    background-color: $color__background--canvas;
    opacity: 75%;
    width: 100vw;
    padding: 1rem 1rem 72px;
  }

  &__mobile-full-min-height {
    min-height: var(--mobile-available-screen);
  }

  &__tools-wrapper {
    position: fixed;
    bottom: 8px;
    width: 99vw;
    margin: 0 0.5vw;
    height: fit-content;
    z-index: map-get($z-indexes, "toolBar");
    @include breakpoint("large+") {
      min-width: 857px;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    transition: all 1s ease 0.1s;
    &.__leader {
      width: 90%;
      left: 50%;
      max-width: 857px;
      min-width: unset;
      transform: translate(-50%, 0);
    }
  }
}
