// instructor pointer and trail
#laserPointer {
  opacity: 1;
  //mix-blend-mode: difference;
  filter: blur(1px);
}

.trail {
  position: absolute;
  filter: blur(4px);
  width: 12px;
  height: 12px;
  background-color: $action-red;
  border-radius: 50%;
  pointer-events: none;
  transition: background-color 0.2s ease-out;
  animation: fadeLaser 0.5s linear;
}

.laser-pointer {
  position: absolute;
  height: 12px;
  width: 12px;
  background-color: $action-red;
  border-radius: 50%;
  cursor: none;
}

// student laser pointer and trail
#laserPointer.student-laser {
  transition: all 0.5s;
  opacity: 1;
}

.student-trail {
  position: absolute;
  filter: blur(4px);
  width: 12px;
  height: 12px;
  background-color: $action-red; 
  border-radius: 50%;
  pointer-events: none;
  transition: all 0.3s;
}

@keyframes fadeLaser {
  0% {
    width: 12px;
    height: 12px;
  }
  40% {
    width: 4px;
    height: 4px;
  }
  100% {
    width: 0px;
    height: 0px;
  }
}
