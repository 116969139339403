.meeting-controls {
  position: fixed;
  z-index: map-get($z-indexes, "toolBar");
  left: 8px;
  top: 50%;
  transform: translate(0, -50%);
}

.instructor-more-actions{
  &__buttons {
    margin-bottom: 1rem;
  }
}