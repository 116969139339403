///////////
// THEME //
///////////
.theme {
  @include theme() {
    .chapter-list__row {
      &:hover {
        color: getThemeValue("color__brand--medium");
      }
    }
  }
}

/////////////
// STYLING //
/////////////
@include breakpoint("xsmall+") {
  .container {
    &.chapter-list-container {
      position: relative;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      z-index: #{map-get($z-indexes, "modal-overlay")};
      gap: 0 !important;
      font-family: $font-family__primary;
      box-shadow: none;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
    }

    .children.active {
      width: 100%;
      height: 100%;
    }
  }

  .chapter-list-container__content {
    flex: 1;
    height: 100%;
    width: 100%;
  }

  .chapter-list-container {
    &__title {
      font-size: 2rem;
      margin-bottom: 0.75rem;
      margin-top: -0.25rem;
    }
  }

  .chapter-list {
    padding: 16px;
    padding-left: 4px;
    overflow-x: clip;
    transition: all 0.25s ease-in-out;
    height: 40vh;
    margin: auto;

    &.--desktop {
      max-width: 40vh;
    }

    &.--mobile {
      width: fit-content;
    }

    &__row {
      padding: 0.5rem;
      cursor: pointer;

      &-active {
        @include box-shadow();
      }

      // Wrapper to make sure that the box shadow is visible when active chapter scrolls to top of list
      &--wrapper {
        padding-top: 0.25rem;
      }

      // Extra div to allow chapters at end of list to scroll to top
      &--buffer {
        height: 0;
      }
    }
  }

  .replay-preview-container.chapter-list__preview {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: 220px;

    .slide__preview {
      max-width: 120px;
      margin: auto;
      width: auto;

      &.slide__preview__iiif {
        height: 75px;
        width: 90px;
      }

      &.icon {
        padding: 11px 0px;
        border: 0.5px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        width: 90px;

        svg {
          height: 48px;
          width: 48px;
        }
      }
    }

    .slide__title {
      display: flex;
      flex-direction: column;
      margin: 0 16px;
      align-items: start;
      width: 60px
    }
  }
}

@include breakpoint("medium+") {
  .container {
    &.chapter-list-container.active {
      font-family: $font-family__primary;
      @include box-shadow();
    }
    &.chapter-list-container{
      overflow-x: initial;
    }
  }

  .chapter-list-container {
    &__content {
      width: 16rem;
    }

    &__title {
      padding-left: 12px;
      padding-bottom: 12px;
      margin-bottom: -0.2rem;
      margin-top: 0;
      font-size: 1.5rem;
    }
  }

  // Extra div to allow chapters at end of list to scroll to top
  .chapter-list__row--buffer {
    height: 31vh;
  }
}