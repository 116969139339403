///////////////
//// MODAL ////
///////////////

///////////////
//// THEME ////
///////////////
.theme {
  @include theme () {
    .modal-background {
      .modal-container {
        background-color: getThemeValue(color__background--light);
      }
    }
  }
}

/////////////////////
//// BREAKPOINTS ////
/////////////////////
@include breakpoint("xsmall+") {
  .modal-background {
    position: absolute;
    top: 0;
    margin-top: 0;
    width: 100vw;
    min-height: 100vh;
    z-index: #{map-get($z-indexes, "tool-tips")};
    &.modal-background--no-background{
      height: 0;
      width: 0;
    }
  }

  .modal-container {
    position: absolute;
    top: 0;
    margin-top: 0;
    width: 100vw;
    min-height: 100vh;
    min-height: var(--mobile-available-screen);
    flex-direction: column;
  }

  .modal-container .close__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 8px;
  }

  .modal-container .children {
    &.active {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      // 4rem is the height of the footer fyi for the below
      height: calc((var(--mobile-available-screen)) - 4rem);
    }
  }

  .__modal-content {
    width: 100%;
    min-height: 11.7rem;
  }
}

@include breakpoint("medium+") {
  .modal-background {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    backdrop-filter: none;
    transition: 0.25s all;
  }

  .modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 0.5rem;
    width: fit-content;
    height: fit-content;
    min-height: auto;
    max-height: 95vh;
    margin: auto;
    flex-direction: initial;
  }

  .__modal-content {
    height: fit-content;
    width: fit-content;
    min-width: 16rem;
  }

  .modal-container .children {
    &.active {
      height: 100%;
    }
  }
}
