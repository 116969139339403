.closed-captions {
  @include transition();
  z-index: map-get($z-indexes, "toolBar");
  display: flex;
  isolation: isolate;
  border-radius: 4px;
  flex-direction: column-reverse;
  order: 0;
  flex-grow: 0;
  height: fit-content;
  width: fit-content;
  padding: 8px;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  width: 100%;
  max-width: 857px; // width of student tools
  position: relative;
  margin: 0 auto;
  max-height: 158px;
  overflow-y: scroll;
  @include breakpoint("xsmall+") {
    max-height: 60vh;
  }
  @include breakpoint("medium+") {
    max-height: 40vh;
  }

  &__line {
    font-size: var(--closed-caption-size);
    max-width: 600px;
    width: auto;
    color: $white;

    &--current {
      font-weight: bolder;
    }
  }

  &__mobile {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: calc($toolbar-height + 16px);
  }

  &__replay {
    position: absolute;
    flex-direction: column;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 80px;
    width: 96%;
    
    .closed-captions__line{
      max-width: unset;
    }

    &--mobile {
      bottom: calc(($toolbar-height * 2) + 16px);
    }
  }
}
