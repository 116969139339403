@import "./variables.scss";
@import "./mixins.scss";

//////////////////////////////
// GENERAL GLOBAL SELECTORS //
//////////////////////////////

* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  // set global css env variables
  --safe-area-top: env(safe-area-inset-top);
  --safe-area-right: env(safe-area-inset-right);
  --safe-area-bottom: env(safe-area-inset-bottom);
  --safe-area-left: env(safe-area-inset-left);
  @include breakpoint("xsmall+") {
    min-height: var(--mobile-available-screen);
  }
  @include breakpoint("medium+") {
    min-height: 100vh;
  }
}

body {
  // CRA boiler plate.
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family__primary;
  font-size: $font-size__body;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

////////////////////
/// GLOBAL FOCUS ///
////////////////////
:focus:not(:focus-visible) {
  outline: none;
}
:focus-visible {
  outline: 2px solid $focus-blue;
  outline-offset: 1px;
}

////////////
// INPUTS //
////////////
input {
  &[type="text"] {
    font-family: $font-family__primary;
    font-size: $font-size__body;
    height: 45px;
    padding: 10px 15px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

textarea {
  font-family: $font-family__primary;
  font-size: $font-size__body;
  height: 45px;
  padding: 10px 15px;
}

/////////////
/// LINKS ///
/////////////
a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.a-basic-link {
  text-decoration: none;
  
  &:hover,
  &:active {
    text-decoration: underline;
  }

  &:focus:not(.focus-ring) {
    text-decoration: underline;
  }
}

.theme {
  @include theme() {
    .a-basic-link {
      color: getThemeValue("color__link");
    }
  }
}


////////////////
/// WRAPPERS ///
////////////////
.relative-wrapper{
  position: relative;
}

////////////
/// TEXT ///
////////////
p {
  max-width: 40em; /* 40 characters for CJK languages */
  margin: auto;
}