/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .course-form {
      .dashboard__table {
        .container.active,
        .container.minimized:hover {
          background-color: getThemeValue("color__background--grey");
        }
      }
    }
  }
}

///////////////////
/// COURSE FORM ///
///////////////////
.course-form {
  &__meeting-time {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
  }

  &__add-cancel .btn-container {
    width: auto;
    min-width: 210px;
  }

  &__footer {
    margin-top: 2.5rem;
    display: flex;
    gap: 1rem;
    flex-basis: revert;
    justify-content: end;
    flex-direction: row;

    .btn-primary {
      margin: auto;
    }
    .btn-container {
      width: auto;
    }
  }
}

.dashboard__table {
  .container.active,
  .container.minimized {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    transition: color 0.25s, background-color 0.25s ease 0.1s;

    &:hover {
      p {
        text-decoration: none !important;
      }
      cursor: pointer;
    }
  }
}

.duplicate-course-wrapper {
  display: flex;
  flex-basis: row;
  gap: 20px;
}

.dashboard-container {
  height: 100%;
  min-height: 800px !important;
  width: 50vw;
  min-width: 800px !important;
  display: flex;
  flex-direction: row !important;
  align-items: flex-start;
  justify-content: center;
  .dashboard-inner {
    width: 100%;
  }
}

.course-item {
  display: flex;
  flex-direction: row;
  h3,
  p,
  p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 25vw;
    max-width: 250px;
  }
}

.__modal-content {
  .course-form {
  height: 60vh;
  overflow: scroll;
  width: 40vw;
  max-width: 800px;
  min-width: 300px;
  }
  .text-input-container {
    width: 95%;
  }
}
