.class-form {
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  gap: 1rem;
  min-height: 3.5rem;
  align-items: center;

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;

    .class-more-actions {
      margin-left: 1rem;
    }
  }

  &__header {
    width: 100%;
    margin: 0;
    font-size: 1.7rem;
    font-weight: bolder;
    &--bottom {
      margin-bottom: 10px;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    .icon-button-wrapper,
    .btn-container {
      margin: 0 0.25rem;
    }
    &.--switch {
      min-width: 200px;
    }
    &--subtext {
      font-size: 0.9rem;
      margin-left: 0;
    }
    &--container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }
  }

  .nav__switch {
    transform: rotate(180deg);
    margin-top: 15px;
    &.switch--active::before {
      background-color: $action-green;
    }
  }
}

.react-calendar {
  border-radius: 0.4rem;
}

.react-datetime-picker__wrapper {
  border-radius: 0.25rem;
}
