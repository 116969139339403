$switch-width: 40px;

/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .switch {
      &::before {
        border: 2px solid getThemeValue("color__button--border");
      }
  
      &::after {
        border: 2px solid getThemeValue("color__copy--medium");
        background-color: #fff;
      }
  
      &--active {
        &::after {
          border-color: getThemeValue(
            "color__button--border"
          ); // $color__red--barnes-dark;
        }
  
        &::before {
          background-color: #{getThemeValue("color__brand--dark")};
          border-color: getThemeValue(
            "color__button--border"
          ); // $color__red--barnes-dark;
        }
      }
      &--disabled{
        &::before {
          background-color: #{getThemeValue("color__copy--medium")};
          border-color: getThemeValue(
            "color__button--border"
          );
        }
      }
    }
  }
}


////////////////
//// SWITCH ////
////////////////

.switch {
  position: relative;
  margin-left: calc(#{$switch-width} - 10px);
  margin-right: 30px;
  margin-bottom: 15px;

  height: 0;
  width: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &::before {
    content: " ";
    display: block;

    position: absolute;
    top: -6px;
    right: -22px;

    width: $switch-width;
    height: 19px;

    transition: background-color 0.2s ease, border-color 0.2s ease;
    border-radius: 12px;
  }

  &::after {
    content: " ";
    position: absolute;
    top: -4px;

    cursor: pointer;
    touch-action: pan-x;

    height: 15px;
    width: 15px;
    border-radius: 50%;

    transition: transform 0.2s ease, border-color 0.2s ease;
    transform: translate3d(1px, 0, 0);
  }

  &--active {
    &::after {
      transform: translate3d(calc((#{$switch-width} - 20px) * -1), 0, 0);
    }
  }
}
