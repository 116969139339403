@import "../../variables.scss";
@import "../../mixins.scss";

@include breakpoint("xsmall+") {
  .add-content {
    cursor: default;
    display: flex;
    flex-direction: column;

    &__right {
      display: flex;
      flex-direction: column;

      .tab-content {
        width: 100%;
        height: 100%;
      }

      .modal_body_section {
        flex-grow: 1;
      }
    }

    &__video-body {
      &__instructions {
        width: 100%;
        margin: 0 auto;
      }

      &__input {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        gap: 1rem;

        .text-input-container {
          margin: 0;
          flex-grow: 1;
        }

        .btn-container {
          width: unset;
        }

        &-button {
          flex-grow: 0;
        }
      }
    }


    &__footer {
      min-width: 33%;
      display: flex;
      gap: 1.5rem;
    }
  }
}

@include breakpoint("medium+") {
  .add-content {
    cursor: default;
    display: flex;
    flex-direction: row;
    height: 75vh;

    &__left {
      display: flex;
      flex-direction: column;
      padding-right: 16px;
      border-right: 1px solid #dcdcdc;
      justify-content: space-between;
    }

    &__right {
      height: 100%;
      padding-left: 16px;
      width: 692px !important;

      .tab-content {
        margin-bottom: 16px;
      }

      .modal_footer_section {
        padding: 4px;
        justify-content: flex-end;
        align-items: flex-end;
      }
    }

    &__header {
      flex-direction: column;
      align-items: flex-start;
      height: 28%;
    }
    &__body {
      height: 72%;
      overflow: hidden;
    }
  }
}