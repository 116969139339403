.edit-iiif-container {
  border: 1px solid $selected-grey;
  position: relative;
  &__viewer {
    height: 100%;
    width: auto;
    overflow: hidden;
    z-index: map-get($z-indexes, "content");
  }
}

.edit-iiif-tools {
  top: -5px;
  left: -5px;
  position: absolute;
  z-index: map-get($z-indexes, "more-actions");
}
