/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .recording-indicator__wrapper {
      .recording-indicator__inner {
        transition: color 0.25s, background-color 0.25s ease 0.1s;
        color: getThemeValue("color__copy");
        background-color: getThemeValue("color__background--light");
      }
    }
  }
}


////////////////////////////////
///// RECORDING INDICATOR  /////
////////////////////////////////

.recording-indicator__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 4px;
  height: 48px;
  width: 48px;
  z-index: 10;
  position: absolute;
  margin: unset;
}