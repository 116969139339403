/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .device-settings {
      &__label,
      label {
        color: getThemeValue("color__copy--aws");
      }
    }
  }
}

////////////////
/// SETTINGS ///
////////////////

.device-settings {
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__header {
    margin: 0;
  }

  &__subheader {
    margin: 0;
  }

  &__label, // to match AWS chime label
  label {
    font-size: 0.875rem;
    line-height: 1.43;
  }

  &__section {
    margin-top: 8px;
  }

  &__body {
    display: flex;
    height: 100%;
    width: 100%;
    margin-bottom: 16px;
    align-items: flex-start;
    justify-content: space-between;
    .audio-container {
      height: 100%;
      width: 48%;
    }
    .video-container {
      height: 100%;
      width: 48%;
      padding-left: 16px;
      border-left: 1px solid $color__copy--dark;
    }
    .mic-activity {
      &__container {
        border-radius: 0.25rem;
        width: 100%;
        height: 0.65rem;
        background-color: #ecf0f1;
      }

      &__indicator {
        background-color: #18bc9c;
        height: 0.625rem;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 33ms ease-in-out;
        will-change: transform;
        border-radius: 0.25rem;
      }
    }
  }

  &__join {
    height: 100%;
    width: 100%;
    margin-bottom: 16px;
  }
}

.audio-check > div {
  align-items: center;
  justify-content: flex-start;
  font-size: 0.875rem;
  line-height: 1.43;
  flex-direction: column;
  margin-bottom: 0.75rem;
  width: fit-content;
  &__toggle{
    display: block !important;
  }
}

.video-preview {
  height: "auto";
  background: unset !important;

  .ch-video {
    position: static;
  }
}
