@include breakpoint("xsmall+") {
  .modal_header_section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0;
  //  min-height: 4.6875rem;
    position: absolute;
    top: 1.25rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 90%;
  }
}

@include breakpoint("medium+") {
  .modal_header_section {
    margin: 0 auto;
    position: relative;
    top: initial;
    left: initial;
    transform: none;
    height: 10%;
    width: 100%;
  }
}
