.iiif-viewer {
  &__blur {
    -webkit-filter: blur(5px) brightness(80%);
    -moz-filter: blur(5px) brightness(80%);
    -o-filter: blur(5px) brightness(80%);
    -ms-filter: blur(5px) brightness(80%);
    filter: blur(5px) brightness(80%);
  }

  &__iiif-grabbable {
    cursor: grab;
  }
  &__iiif-drag {
    cursor: grabbing;
  }
}

.iiif-editor{
  position: absolute;
  left: 1%;
  width: 98%;
  margin: 0 auto;
  height: 50vh;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  transform: translateY(50%);
}
