.pop-up {
  @include breakpoint("xsmall+") {
    height: calc((var(--mobile-available-screen)) - 4rem);
  }
  @include breakpoint("medium+") {
    min-width: 400px !important;
    max-width: 500px !important;
    height: auto;
  }
  &__details {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 4px;
  }
  &__buttons {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-primary,
    .btn-secondary {
      margin: 4px;
    }
  }
  &__user-message {
    display: inline-block;
  }
}
