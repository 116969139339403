@import "../variables.scss";
@import "../mixins.scss";

$fixed-height: 60vh; // This is the height of the body

// Styles for small screens
@include breakpoint("xsmall+") {
  .lobby-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;

    h2 {
      margin: 0;
      font-size: calc(1em + 1vw); /* Responsive font size */
      overflow-wrap: break-word; /* Allow long words to break */
    }

    p {
      margin: 0;
      overflow-wrap: break-word; /* Allow long words to break */
      max-width: none;
    }
  }

  .lobby-container {
    position: fixed;
    
    .__modal-content {
      height: 100%;
      overflow: hidden; /* Prevent content overflow */
    }

    p {
      font-size: 16px;
    }

    .lobby-content__header,
    .lobby-content__footer {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 8px 0;
    }

    .lobby-modal-body-container {
      width: 100%;
      height: $fixed-height;
      display: flex;
      flex-direction: column;
      overflow-y: auto; /* Enable vertical scrolling if needed */

      .lobby-content__mobile {
        height: auto;
        flex-shrink: 1;
        width: 100%;

        .lobby-content__left {
          width: 100%;

          .tab-list {
            .btn-container {
              .btn-tertiary {
                border: 2px solid $color__copy--light;
              }
            }
          }
        }

        .lobby-content__right {
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          margin-top: 8px;

          .chat-container {
            height: auto;

            .chat-container-children {
              height: auto;

              &.active {
                height: auto;
              }
            }
          }

          .device-settings__body {
            margin: 0;
          }

          .instructions-container {
            height: auto;
            width: 100%;

            .modal_body_section {
              top: auto;
              height: auto;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .container.chat-container.lobby-chat-container {
    width: 75vw;

    .chat-container__chat-content {
      width: 100%;
    }
  }
}

// Medium Screen Styles
@include breakpoint("medium+") {
  .lobby-text {
    justify-content: center;
    text-align: center;
  }
  .lobby-container {
    .__modal-content {
      width: 80vw;
      height: 90vh;
      display: flex;
      flex-direction: column;
    }

    .tab-list {
      flex-direction: row;
      gap: 10px;
      padding-bottom: 1rem;

      .btn-tertiary {
        border: 2px solid $color__copy--light;
      }
    }

    .lobby-modal-body-container {
      height: $fixed-height;
      flex-grow: 0;
      box-shadow: none;
      overflow: hidden; /* Prevent content overflow */
    }
  }

  .lobby-content {
    justify-content: flex-start;

    &__left {
      width: 100%;
    }

    &__right {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__header,
    &__footer {
      height: fit-content;
      flex-grow: 1;
    }
  }

  .container.chat-container.lobby-chat-container {
    max-width: 400px;
  }
}

// Large Screens Styles
@include breakpoint("large+") {

  .lobby-content {
    display: flex;
    flex-direction: row !important;
    justify-content: center;

    &__left {
      padding-right: 16px;
      border-right: 1px solid #dcdcdc;
      height: 100%;
      width: fit-content;
    }

    &__right {
      height: 100%;
      width: 692px !important;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .device-settings,
      .chat-focus-lock,
      .instructions-container {
        width: 100%;
        height: 100%;
      }

      .modal_body_section {
        flex-grow: 1;
      }

      .device-settings {
        padding: 24px;
      }
    }

    &__header,
    &__footer {
      align-items: center;
      justify-content: center;
      height: 25%;
    }
  }

  .lobby-container {
    min-height: 800px;
    height: 100%;
    display: flex;
    flex-direction: row !important;
    align-items: flex-start;
    justify-content: center;
    max-width: 925px;

    .__modal-content {
      display: unset;
    }

    .children,
    .__modal-content {
      height: 100%;
      width: fit-content;

      .lobby-modal-body-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        height: $fixed-height;
        box-shadow: none;

        .container {
          box-shadow: none;
          width: 100%;
        }

        .ch-video {
          max-height: 30vh;
        }

        .device-settings {
          height: 50vh;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .modal_body_section {
            margin-top: 0;
            height: unset;
          }
        }

        .lobby-chat-container .chat-container-children {
          height: unset;
        }
      }

      .modal_header_section {
        height: 20%;
        margin: 0;
      }
    }

    .tab-list {
      flex-direction: column;
      border-bottom: unset;

      .btn-tertiary {
        border: none;
      }
    }
  }

  .container.chat-container.lobby-chat-container {
    width: 50%;
    height: 100%;
  }
}
