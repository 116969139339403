/////////////
/// THEME ///
/////////////

.theme {
  @include theme() {
    .admin {
      color: getThemeValue("color__copy");
      background-color: getThemeValue("color__background--dark");
      a {
        color: getThemeValue("color__copy");
      }
      .admin-inner {
        color: getThemeValue("color__copy");
        background-color: getThemeValue("color__background--light");
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      }
      .abcRioButtonBlue {
        background-color: getThemeValue("color__copy--dark");
        color: getThemeValue("color__contrast");
        border: 2px solid getThemeValue("color__copy--dark");

        &:active,
        &:hover {
          background-color: getThemeValue("color__brand--dark");
          outline-color: getThemeValue("color__brand--dark");
          outline-color: getThemeValue("color__brand--dark");
          border: 1px solid getThemeValue("color__brand--dark");
        }
      }
      .react-datetime-picker {
        min-width: 250px;
        &--enabled {
          &.inputGroup,
          input,
          select {
            color: getThemeValue("color__copy");
          }
        }
        &--disabled {
          background-color: getThemeValue("color__background--grey");
        }
      }
    }
  }
}

/////////////
/// ADMIN ///
/////////////
@include breakpoint("xsmall+") {
  .admin-container {
    font-family: $font-family__primary;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;
    .admin-inner {
      padding: 16px;
      border-radius: 1rem;
    }
  }
}

@include breakpoint("medium+") {
  .admin-inner {
    padding: 16px;
    border-radius: 1rem;
    min-width: 400px;
    margin-bottom: 10%;
  }
}

.admin {
  .admin-content {
    padding: #{map-get($pagePadding, "xsmall")};
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 6.25rem;
    padding: auto;
    justify-content: flex-start;
    align-items: flex-start;

    @include breakpoint("medium+") {
      padding: #{map-get($pagePadding, "medium")};
      padding-top: #{map-get($pagePadding, "xsmall")};
    }

    @include breakpoint("large+") {
      padding: #{map-get($pagePadding, "large")};
      padding-top: #{map-get($pagePadding, "small")};
    }

    @include breakpoint("xlarge+") {
      padding: #{map-get($pagePadding, "xlarge")};
      padding-top: #{map-get($pagePadding, "medium")};
    }
  }
  transition: color 0.25s, background-color 0.25s ease 0.1s;
  height: 100vh;
  overflow-y: auto;
  &__button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    .abcRioButtonBlue {
      border-radius: 4px;
      width: 100%;

      .abcRioButtonContentWrapper {
        border: unset;
      }
    }

    .abcRioButton {
      box-shadow: unset;

      &:hover {
        box-shadow: unset;
      }
    }
  }
}

.console {
  padding: 0px 40px 30px;
  width: 100%;
  position: relative;

  &__header-section {
    display: flex;
    flex-direction: column;
  }

  &__header {
    margin: 0 0 5px;

    font-size: $font-size__header--desktop;
    line-height: 1.2;
  }

  &__subheader {
    margin: 0 0 5px;

    font-size: $font-size__sub-header;
    line-height: 1.2;
  }

  &__detail {
    margin: 10px 0;

    font-size: $font-size__body;
    line-height: 1.2;

    max-width: 380px;
  }

  &__button {
    max-width: 320px;

    &--sign-out {
      position: absolute;
      top: 0;
      right: 40px;
      max-width: 120px;
      height: 40px;
      line-height: 35px;
    }
  }

  &__content {
    margin-top: 30px;
    width: 100%;
  }

  &__course {
    text-decoration: none;
  }

  &__table {
    border-collapse: collapse;
    width: 100%;

    display: block;
    margin-top: 20px;
    margin-bottom: 32px;
    padding-bottom: 32px;

    &:last-of-type {
      border: none;
    }

    table-layout: fixed;

    th,
    td {
      padding: 0 20px;
      width: calc(100% / 6);
    }

    tbody {
      & > tr {
        cursor: pointer;
      }
    }

    tr {
      font-size: $font-size__body;
      height: 55px;

      position: relative;
    }

    th {
      font-size: $font-size__sub-header;
      text-align: left;
    }
  }

  &__table-spinner {
    display: flex;
    align-items: center;
    height: 100px;

    & > div {
      // Opacity is transitioned using JS.
      transition: opacity 0.4s;

      margin: 0;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: italic;
    }
  }
}
