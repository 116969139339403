/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .progress {
      .progress-container {
        background: getThemeValue("color__background--grey");
      }

      .progress-container::before {
        background: getThemeValue("color__brand--medium");
      }
    }

    .progress-container {
      background: getThemeValue("color__background--medium");

      .chapter-marker {
        border-right: 2px solid getThemeValue("color__background--light");

        &__preview {
          background-color: getThemeValue("color__background--light");
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
        }

        &:hover {
          background-color: getThemeValue("color__background--medium");
        }

        &--past-chapter:hover {
          background-color: getThemeValue("color__brand--medium");
        }
      }

      .sub-chapter {
        background-color: #6e6e6e;

        &--past-chapter {
          background-color: getThemeValue("color__brand--medium");
        }
      }

      .current-chapter-played {
        background-color: getThemeValue("color__brand--medium");
      }
    }

    .progress-container::before {
      background: getThemeValue("color__brand--medium");
    }

    .progress-bar {
      background: getThemeValue("color__brand--medium");
    }

    .progress-overlay {
      background: getThemeValue("color__copy--medium");
    }
  }
}

////////////////////
/// PROGRESS BAR ///
////////////////////

@include breakpoint("xsmall+") {
  progress {
    opacity: 0;
  }

  .progress-container {
    position: relative;
    display: inline-block;
    height: 12px;
    /* Initial height */
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    cursor: pointer;

    &.--disabled {
      cursor: auto;
    }

    .chapter-marker {
      position: absolute;
      bottom: 0;
      cursor: pointer;
      padding: 8px 2px;
      z-index: 11;
      /* Set a higher z-index to place it in front of the hover */

      /* Add transition effect to height */
      transition: height 0.3s ease;
      /* Add a transition effect on the height property */

      &__preview {
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        transition: visibility 0.1s ease;
        position: absolute;
        bottom: 24px;
        width: $chapter-marker-preview-width;
        height: 148px;
        border-radius: 6px;
        padding: 8px;

        &#chapter-marker__touch-preview {
          transition: visibility 1s ease;
        }
      }

      &:hover {
        height: 16px;
        top: -2px;
        transition: height 0.3s ease;

        .progress-overlay {
          width: var(--w-hover);
          /* Change the width of the overlay on hover */
        }

        &:hover+.chapter-marker__preview {
          visibility: visible;
        }
      }
    }

    .chapter-marker.active-chapter {
      height: 12px !important;
    }

    .sub-chapter {
      // transition: width 1s ease; /* Add a transition effect on the width property */
      position: absolute;
      height: 16px;
      margin: 0;
      bottom: 0px;
      left: 0px;
    }

    .chapter-marker:hover .sub-chapter+.current-chapter-played {
      height: 16px;
      top: 0;
    }

    .current-chapter-played {
      position: absolute;
      left: 0;
      top: 4px;
      height: 12px;
    }
  }

  .progress-container--replay-container {
    overflow: visible;
  }

  .progress-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: var(--w);
    z-index: 10;
    /* Set a higher z-index to place it in front of the hover */
  }

  .progress-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    /* Initial width is 0, it will be updated on hover */
    transition: width 0.3s ease;
    /* Add a transition effect on the width property */
    z-index: 0;

    /* Set a lower z-index to place it behind the red progress */
    &--chapters {
      transition: width 0s;
      /* Remove a transition effect on the width property if chaptered */
    }
  }
}