/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .videos {
      transition: color 0.25s, background-color 0.25s ease 0.1s;
      border-radius: 10px;
      color: getThemeValue("color__copy");
      background-color: getThemeValue("color__background--light");

      .videos__user {
        background-color: getThemeValue("color__background--light");
        &-dismiss {
          background-color: getThemeValue("color__contrast");
        }
      }
      .student__wrapper {
        color: getThemeValue("color__copy");
        background-color: getThemeValue("color__background--light");
      }
      .instructor__wrapper {
        border: 6px solid getThemeValue(color__background--light);
        &.__small-instructor {
          border: 10px solid getThemeValue(color__background--light);
        }

        &.__replay {
          .video__wrapper {
            width: 100%;
            height: auto;
            aspect-ratio: $aspectRatio;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            .video__tile {
              width: 100%;
              height: auto;
              aspect-ratio: $aspectRatio;
            }
          }
          // Reset default videojs player dimensions for replay
          .video-js {
            height: 100%;
            width: 100%;
            margin-left: -3px;
          }
        }
      }
    }
  }
}

////////////////////////////////////////////////////////
/// small screen / med+ instructor / student styling ///
////////////////////////////////////////////////////////

@include breakpoint("xsmall+") {
  .videos {
    .icon-tool__inner {
      bottom: 8px;
      right: 4px;
      z-index: map-get($z-indexes, "video");
    }
    @media (649px < width < 750px) {
      .user-video-toggle__inner {
        bottom: 60px;
      }
    }
  }
  .videos__wrapper {
    z-index: map-get($z-indexes, "video");
    position: fixed;
    top: 8px; // TODO: make 48px
    width: 100%;
    height: 1px;
    border-radius: 6px;
  }
  .instructor__wrapper {
    width: 284px;
    height: 184px;
    z-index: map-get($z-indexes, "video");
    margin: 0 auto;
    margin-left: 12px;
    margin-right: auto;
    border-radius: 6px;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 20%);
    &.__spotlight {
      @include transition();
      position: fixed;
      width: 60vw !important;
      height: auto !important;
      right: 50%;
      top: 50%;
      transform: translate(50%, -50%);
      .video__wrapper {
        width: 100%;
        height: auto;
        aspect-ratio: $aspectRatio;
        .video__tile {
          width: 100%;
          height: auto;
          aspect-ratio: $aspectRatio;
        }
      }
    }
    &.__replay {
      @include transition();
      position: fixed;
      width: 277px !important;
      height: auto !important;
      left: 0;
      top: 8px;
      right: unset;
      &.__mobile-instructor {
        width: 50vw !important;
        max-width: 200px;
      }
      .video__wrapper {
        width: 100%;
        height: auto;
        aspect-ratio: $aspectRatio;
        .video__tile {
          width: 100%;
          height: auto;
          aspect-ratio: $aspectRatio;
          margin-left: 3px;
        }
      }

      &.show {
        opacity: 1;
      }

      &.hide {
        opacity: 0;
      }
    }
    &.__replay.__spotlight {
      @include transition();
      position: fixed;
      width: 60vw !important;
      height: auto !important;
      left: unset !important;
      right: 50% !important;
      top: 50% !important;
      transform: translate(50%, -50%) !important;
      max-width: unset !important;
      .video__wrapper {
        width: 100%;
        height: auto;
        aspect-ratio: $aspectRatio;
        .video__tile {
          width: 100%;
          height: auto;
          aspect-ratio: $aspectRatio;
          margin-left: 3px;
        }
      }
    }
    &.__replay.__mobile-instructor.__spotlight {
      @include transition();
      position: fixed;
      width: 75vw !important;
      height: auto !important;
      transform: translate(50%, -50%) !important;
      .video__wrapper {
        width: 100%;
        height: auto;
        aspect-ratio: $aspectRatio;
        .video__tile {
          width: 100%;
          height: auto;
          aspect-ratio: $aspectRatio;
        }
      }
    }
    &.__small-instructor {
      margin-top: 0;
    }
    &.__mobile-instructor {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 4px;
      margin-right: auto;
      margin-top: -4px;
      height: 100px;
      width: 165px;
      .video__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 92px;
        width: 157px;
        .video__tile {
          height: 92px;
          width: 157px;
        }
        .icon-button-wrapper {
          .videos__user-dismiss {
            display: inline;
            position: absolute;
            border-radius: 4px;
            padding: 1px;
            height: 22px;
          }
        }
      }
      &.__spotlight {
        @include transition();
        position: fixed;
        width: 70vw;
        height: auto;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        aspect-ratio: $aspectRatio;
        .videos__instructor {
          height: auto;
          width: 70vw;
        }
        .video__wrapper {
          width: 100%;
          height: auto;
          aspect-ratio: 16 /9;
          .video__tile {
            width: 100%;
            height: auto;
            aspect-ratio: 16 /9;
          }
        }
      }
    }
  }
  .student__wrapper {
    // background: $color__background--light;
    border-radius: 6px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    max-width: 98%;
    height: 118px;
  }
}
@include breakpoint("medium+") {
  .videos__wrapper {
    top: 8px;
    display: flex;
    flex-basis: auto;
    gap: 6px;
    justify-content: center;
  }
  .student__wrapper {
    width: 100%;
    max-width: 848px;
    display: flex;
    flex-direction: column;

    &:hover {
      .icon-button-wrapper {
        .videos__students-dismiss {
          display: inline;
        }
      }
    }
    .icon-button-wrapper {
      .videos__students-dismiss {
        position: absolute;
        right: 0;
        z-index: map-get($z-indexes, "video");
        display: none;
        border-radius: 4px;
        padding: 1px;
        height: 22px;
      }
    }

    &.__all-participants {
      width: 80%;
    }
  }
}

//////////////////////////////////////////////

.student-videos__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 20px;
  gap: 8px;

  .video__wrapper {
    max-height: 98px;
    max-width: 160px;
  }
}

.videos__user {
  z-index: map-get($z-indexes, "video");
  position: fixed;
  height: 100px;
  width: 165px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 4px;
  margin: unset;

  &.__spotlight {
    @include transition();
    position: fixed;
    width: 60vw;
    height: fit-content;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    aspect-ratio: $aspectRatio;
    .video__wrapper {
      width: 100%;
      height: fit-content;
      aspect-ratio: 16 /9;
      .video__tile {
        width: 100%;
        height: fit-content;
        aspect-ratio: 16 /9;
      }
    }
  }

  @include breakpoint("xsmall+") {
    bottom: 64px;
    right: 50%;
    transform: translate(50%, 0);
  }

  @include breakpoint("small+") {
    right: 8px;
    bottom: 64px;
    transform: unset;
  }

  @include breakpoint("xlarge+") {
    bottom: 8px;
    right: 8px;
  }

  .video__wrapper {
    position: relative;

    .video__tile {
      height: 92px;
      width: 157px;
    }

    &:hover {
      .videos__user-dismiss {
        display: inline;
      }
    }
  }

  &-dismiss {
    position: absolute;
    top: 2px;
    right: 2px;
    display: none;
    border-radius: 4px;
    padding: 1px;
    height: 22px;
  }
}

.video__wrapper {
  height: 100%;
  width: 100%;
  position: relative;

  .video__tile {
    height: 92px;
    width: 157px;
  }

  &:hover {
    .videos__user-dismiss {
      display: inline;
    }
  }

  .videos__user-dismiss {
    position: absolute;
    top: 2px;
    right: 2px;
    display: none;
    border-radius: 12px;
    padding: 1px;
    height: 24px;
    width: 24px;
    &--manual-spotlight {
      right: 30px;
      width: 24px;
      height: 24px;
    }
  }

  &--hand-raised {
    position: absolute;
    display: block;
    right: 5px;
    top: 5px;
    animation: fadeInAnimation ease 0.25s;
    -webkit-animation: fadeInAnimation ease 0.25s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }

  // Class from AWS SDK
  .ch-nameplate {
    z-index: map-get($z-indexes, "video");
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background-color: rgba(46, 47, 52, 0.85);
    color: #fff;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.02em;
    height: 18px;
    width: fit-content;
    max-width: calc(100% - 28px);
    padding: 2px 4px;
    visibility: hidden;

    &.--icon {
      width: 18px;
      height: 18px;
      visibility: visible;
    }

    p {
      font-size: 0.875rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      margin-left: 4px;
      max-width: 100%;
    }
  }

  &:hover .ch-nameplate {
    visibility: visible;
  }

  .video__tile {
    height: 98px;
    width: 160px;
    border-radius: 2px;
    display: inline-block;
    position: relative;

    &-featured {
      box-shadow: 0 0 0 4px $videoGreen;
    }

    &-hand-raised {
      box-shadow: 0 0 0 4px $color__yellow;
    }

    &-instructor {
      position: absolute;
      width: 273px;
      height: 173px;
    }

    &-initials {
      height: 100%;
      width: 100%;
      background: #010101;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
    }

    &-header {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 4px;

      &.--video-on {
        position: absolute;
        top: 0;
        left: 0;
        justify-content: flex-end;
      }
    }

    .ch-video {
      border-radius: 2px;
    }
  }
}

///////////// ANIMATIONS //////////////////
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
