@include breakpoint("xsmall+") {
  .add-board-container {
    height: 92vh;
    max-width: 26rem;
    margin: 0 auto 0;
  }

  .add-board {
    &__heading {
      font-size: 1.5rem;
      margin-top: 0;
    }

    &__error {
      text-align: center;
      margin-top: 2.5rem;
    }

    &__error .icon {
      position: unset;
    }
  }
}

@include breakpoint("medium+") {
  .add-board-container {
    height: unset;
    width: 26rem;
    max-width: 400px;
  }
}

.add-board__form {
  &__sort-order {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    border: 2px solid #282828;
    //padding: 12px;
    font-family: $font-family__primary;
    width: 180px;
  }
  &__sort-select {
    width: 40px;
    height: 25px;
    border-radius: 6px;
    font-family: $font-family__primary;
    font-size: 1rem;
    border: 1px solid #282828;
  }
  &__caption {
    border-radius: 4px;
    border: 2px solid $color__copy--dark;
    opacity: 80;
    width: 100%;

    .rsw-btn:focus {
      outline: 2px solid $focus-blue;
      outline-offset: 1px;
    }

    &__submit {
      margin-top: 1.5rem;

      .rsw-editor {
        border: unset;
        border-radius: 4px;
      }
    }

    &__submit {
      margin-top: 1.5rem;
    }
  }
}
