.icon-button-wrapper {
  .icon-button {
    border: none;
    background: rgba(255, 255, 255, 0.95);
    background: transparent;
    cursor: pointer;
    height: fit-content;
    width: fit-content;
    padding: 4px;
    margin: auto;

    &:disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
    }

  }

  .counter {
    width: fit-content;
    position: absolute;
    padding: 0 0.5rem;
    border-radius: 10px;
    margin-left: 24px;
    margin-top: -40px;
    color: $white;
    font-family: $font-family__primary;
    font-size: 0.9rem;
    background-color: $action-green;
  }
}
