///////////////
//// THEME ////
///////////////
.theme {
  @include theme() {
    ///// CHIME SDK COMPONENTS /////
    .participant-list-header button:hover {
      background-color: initial !important;
      color: getThemeValue("color__brand--dark");
    }

    .participant-list-header {
      border-bottom: 1px solid getThemeValue("color__grey");
    }

    ///// VxP COMPONENTS /////
    .participant-list-container {
      .roster-participant-wrapper {
        &:hover {
          background-color: getThemeValue("color__background--dark");
        }

        .roster-participant__tool-tip {
          background-color: getThemeValue("color__copy");
          color: getThemeValue("color__contrast");

          &:after {
            border-color: getThemeValue("color__copy") transparent transparent transparent;
          }
        }
      }
    }
  }
}

///////////////////////
/// COMPONENT STYLE ///
///////////////////////

///// CHIME SDK COMPONENTS /////
.participant-list-header button:hover {
  border: none !important;
}

.participant-list-header button:focus {
  box-shadow: 0 0 0 0.125rem $focus-blue !important;
}

.participant-list-header {
  margin: 0 0.5rem;
}

///// VxP COMPONENTS /////
.participant-list-container {
  .roster-participant-wrapper {
    &:hover {
      border-radius: 6px;
    }
  }
}

.roster-participant-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  height: fit-content;
  margin: 0 0.5rem;
  padding: 0 8px;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: calc(100% - 1rem);
  position: relative;

  &__name-initials {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 6px;
    align-items: center;
    overflow-x: hidden;
    padding: 8px 0;

    .__name {
      overflow: hidden;
      width: 80%;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-bottom: 5px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  // Soft ban styling
  &.__soft-ban {
    .roster-participant__tool-tip {
      visibility: hidden;
      width: auto;
      text-align: center;
      border-radius: 4px;
      padding: 8px 12px;
      font-family: $font-family__primary;
      z-index: map-get($z-indexes, "tool-tips");
      width: max-content;
      max-width: 225px;
      cursor: pointer;
      text-align: center;
      border-radius: 4px;
      padding: 8px 12px;
      word-wrap: break-word;
      font-size: 14px;

      position: absolute;
      top: -4rem;

      // Styling for the caret that points from the tool tip to the icon
      &:after {
        content: "";
        border-width: 5px;
        border-style: solid;

        position: absolute;
        top: 100%;
        left: 11px;
      }
    }

    &:hover {
      .roster-participant__tool-tip {
        visibility: visible;

        &.__hide-tool-tip {
          visibility: hidden;
        }
      }
    }

    .__name {
      color: $color__copy--light;
      font-style: italic;
    }
  }
}

.participant-list-filter-wrapper {
  .filter-container {
    .filter-button {
      margin: 4px;
      min-width: initial !important;
      .btn-container {
        width: initial !important;
      }
    }
  }
}
.participant-list-container {
  height: 425px;
  width: 100%;

  &__headings {
    padding-left: 12px;
  }

  .--participant-speaking {
    box-shadow: 0 0 0 0 3px $videoGreen inset;
  }

  &__roster {
    overflow-x: hidden;
  }
}

.chat-live-area__chat-initials {
  &__participant-hand-raised {
    border: 2px solid $color__yellow;
    padding-top: 4px;
  }

  &__participant-speaking {
    border: 2px solid $videoGreen;
    padding-top: 4px;
  }
}
