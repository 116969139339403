@include breakpoint("xsmall+") {
  .modal_body_section {
    max-height: calc((var(--mobile-available-screen) - 190px));
    transform: translate(-50%, 0);
    overflow: auto;
    margin-top: 4.6875rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0;
    position: absolute;
    top: 1.25rem;
    left: 50%;
    width: 90%;
  }
}

@include breakpoint("medium+") {
  .modal_body_section {
    margin-top: 0;
    margin: 0 auto;
    position: relative;
    top: initial;
    left: initial;
    transform: none;
    height: 80%;
    width: 100%;
    // min-height: 18rem;
  }
}
