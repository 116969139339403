@import "./variables.scss";

////////////
// MIXINS //
////////////

@mixin stacking-btn-margin-fix() {
  .m-btn-group {
    margin-top: 0;

    &:first-child,
    .btn {
      margin-top: 17px;
    }
  }
}

@mixin set-top-header-height() {
  top: $site-header-height-xsmall;

  // match the header heights in the styleguide
  @include breakpoint("medium+") {
    top: $site-header-height-medium;
  }

  @include breakpoint("large+") {
    top: $site-header-height-large;
  }

  @include breakpoint("xlarge+") {
    top: $site-header-height-xlarge;
  }
}

@mixin clearfix() {
  *zoom: 1;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}
@mixin zeta() {
  color: $color__copy--medium;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 1.5625;
}

// __card padding and coloring
@mixin card() {
  color: $black;
  background-color: $button-color;
  border: $button-border;
  padding: 10px 15px 10px 15px;
}

// __description element.
@mixin description() {
  color: $color__copy--medium;

  &--title {
    color: $black;
    font-size: 27px;
    font-weight: bold;
  }

  &--sub-header {
    color: $black;

    font-weight: 700;
    margin-bottom: 20px;
  }

  &--detail {
    @include zeta();
    color: $black;
  }
}

// Drop shadow
@mixin box-shadow() {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
}

// Transition animation
@mixin transition() {
  transition: opacity 0.25s ease 0.1s;
}

/////////////////
// BREAKPOINTS //
/////////////////

/*
@function get-breakpoint-directions
Sorts through breakpoints SASS map,
generates a full SASS map containing all the breakpoint
variations we'll require
Parameters:
none
*/
@function get-breakpoint-directions() {
  $_bps: ();
  @each $k, $bp in $breakpoints {
    $_bps: map-merge(
      $_bps,
      (
        $k: $bp,
      )
    );
    $start: map-get($bp, start);
    $end: map-get($bp, end);

    @if $end != null and $start != null {
      $down-key: unquote($k + "-");
      $_bps: map-merge(
        $_bps,
        (
          $down-key: (
            start: null,
            end: $end,
          ),
        )
      );
    }

    @if $start != null and $end != null {
      $up-key: unquote($k) + "+";
      $_bps: map-merge(
        $_bps,
        (
          $up-key: (
            start: $start,
            end: null,
          ),
        )
      );
    }
  }

  @return $_bps;
}

$breakpoints-with-directions: get-breakpoint-directions();

/*
@mixin breakpoint
Inserts a media query
Parameters:
$name - name of breakpoint, choose from:
1
xsmall, small, medium, large, xlarge, xxlarge  - *just* that breakpoint
2
small-, medium-, large-, xlarge-, xxlarge-  - that breakpoint *and* below
3
xsmall+, small+, medium+, large+, xlarge+  - that breakpoint *and* up
4
Apply CSS upto the large+ breakpoint to also be Print CSS
NB: the we're mobile up, so the minus values should be avoided..
*/

@mixin breakpoint($name) {
  $points: map-get($breakpoints-with-directions, $name);
  @if $points {
    $media: get-media($points);
    $start: map-get($media, "start");
    $end: map-get($media, "end");
    $str: "screen and (";

    // 1
    @if ($start != null) {
      $str: $str + "min-width: " + ($start * 1px);
    }

    // 2
    @if ($start != null and $end != null) {
      $str: $str + ") and (";
    }
    // 3
    @if ($end != null) {
      $str: $str + "max-width: " + ($end * 1px);
    }
    $str: $str + ")";
    // 4
    @if ($end == null and $start != null and $name != "xlarge+") {
      $str: "print, " + $str;
    }

    @media #{$str} {
      @content;
    }
  } @else {
    @warn "Unknown breakpoint `#{$name}` in $breakpoints.";
  }
}

/*
@function get-media
Returns start and stop points of a given media query
Parameters:
$bp - the breakpoint you want the stop and stop points of
*/

@function get-media($bp) {
  $start: null;
  $end: null;

  $bp-start: map-get($bp, "start");
  $bp-end: map-get($bp, "end");
  @if ($bp-start != null and ($start == null or $bp-start < $start)) {
    $start: $bp-start;
  }
  @if ($bp-end != null and ($end == null or $bp-end > $end)) {
    $end: $bp-end;
  }

  @return (start: $start, end: $end);
}

//////////////////
// THEME SWITCH //
//////////////////

/**
* Calculate modifier provided to theme
*/
@function getModifier($themeName) {
  @if $themeName == "default" {
    @return "";
  } @else {
    @return "--#{$themeName}";
  }
}

/**
* Mixin to use to generate blocks for each theme
* Automatically takes @content
*/
$scopedTheme: null;
@mixin theme($allThemesMap: $themes) {
  @each $themeName, $themeMap in $allThemesMap {
    &#{getModifier($themeName)} {
      // Creating a map that contains values specific to theme.
      // Global is necessary since in mixin
      $scopedTheme: () !global;
      @each $variableName, $variableValue in $themeMap {
        // Merge each key-value pair into the theme specific map
        $scopedTheme: map-merge(
          $scopedTheme,
          (
            $variableName: $variableValue,
          )
        ) !global;
      }
      // The original content passed
      @content;
      // Unset
      $scopedTheme: null !global;
    }
  }
}

/**
* Function to call within theme mixin, to get value from the current theme in the iterator
*/
@function getThemeValue($themeVar) {
  @return map-get($scopedTheme, $themeVar);
}

/**
* EXAMPLE USAGE:
* Actually using theme values to generate CSS

.myComponent {
    font-size: 16px;
    
    @include theme() {
        color: getThemeValue('secondary');
    }
    
    &__thing {
        margin: 10px;
        
        @include theme() {
            background-color: getThemeValue('primary');
            color: getThemeValue('secondary');
        }
    }
    
}

*/
