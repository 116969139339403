///////////////
/// MEETING ///
///////////////
.theme {
  @include theme() {
    .meeting {
      color: getThemeValue("color__copy");
      background-color: getThemeValue("color__background--canvas");
    }
  }
}
.meeting {
  position: relative;
  min-height: 100vh;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  transition: color 0.25s, background-color 0.25s ease 0.1s;
  &__mobile-full-min-height {
    min-height: var(--mobile-available-screen);
  }
}

// set the desktop chat to be on the right side
.meeting-chat-container{
  position: absolute;
      right: 0.5rem;
      top: calc(35vh - 100px);
}

