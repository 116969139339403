/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .toggle-container, .toggle-container.mobile-tools {
      .toggle {
        display: flex;
        .toggle-inner {
          background-color: getThemeValue("color__background--grey");
  
          &:after {
            background: getThemeValue("color__background--light");
            color: getThemeValue("color__brand--medium");
          }
        }
  
        label {
          color: getThemeValue("color__copy--dark");
        }
  
        input {
          &:checked+label.toggle-inner:after {
            background: getThemeValue("white");
            color: getThemeValue("color__brand--medium");
          }
  
          &:checked+label.toggle-inner:before {
            color: getThemeValue("color__copy--dark");
          }
        }
  
        // Disabled
        .toggle-inner.disabled:before {
          color: getThemeValue("color__copy--medium");
        }
      }
    }
  }
}

//////////////
/// TOGGLE ///
//////////////

@include breakpoint("xsmall+") {
  .toggle-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .toggle {
      position: relative;
      display: inline-block;

      &__icon-one {
        position: absolute;
        left: 16px;
        top: 5px;
        z-index: map-get($z-indexes, "icon");
      }

      &__icon-two {
        z-index: map-get($z-indexes, "icon");
        position: absolute;
        top: 5px;
        left: 135px;
      }

      .toggle-inner {
        cursor: pointer;
        margin: 0px;
        width: 246px;
        height: 32px;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        -webkit-transition: 0.2s;
        transition: 0.2s;
        display: block;

        &:before {
          // TODO move this to styled components and change icon color
          // content: url(../../../public/icons/explore.svg) attr(data-option1);
          content: attr(data-option1);
          position: absolute;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          right: 15%;
          top: 25%;
        }

        &:after {
          // TODO move this to styled components and change icon color
          // content: url(../../../public/icons/focus.svg) attr(data-option2);
          content: attr(data-option2);
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          height: 28px;
          width: 120px;
          border-radius: 2px;
          left: 2px;
          top: 2px;
          -webkit-transition: 0.2s;
          transition: 0.2s;
        }

        &.disabled {
          cursor: default;
        }
      }

      label {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
      }

      /* Hide default HTML checkbox */
      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked+label.toggle-inner:after {
          // TODO move this to styled components
          // content: url(../../../public/icons/explore.svg) attr(data-option1);
          content: attr(data-option1);
          top: 2px;
          left: 124px;
        }

        &:checked+label.toggle-inner:before {
          // TODO move this to styled components
          // content: url(../../../public/icons/focus.svg) attr(data-option2);
          content: attr(data-option2);
          top: 25%;
          right: 168px;
        }
      }
    }
  }
}

