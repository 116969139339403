.art-object-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;

  .art-object-caption {
    .h2 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &.selected {
    visibility: visible;
    opacity: 1;

    .art-object-caption {
      visibility: visible;
      opacity: 1;
    }
  }

  &:hover {
    .art-object-caption {
      visibility: visible;
      opacity: 1;
    }
  }

  .art-object-caption {
    @include breakpoint("medium+") {
      visibility: hidden;
      opacity: 0;
    }

    height: fit-content;
    width: 100%;
    bottom: 0;
    left: 0;
    color: $black;
    padding: 12px 16px;
    background-color: $white;
    transition: all 0.25s 0.1s ease-in-out;

    .h2 {
      font-weight: 500;
      font-size: 14px;
      margin: 0px;
    }

    .h3 {
      font-weight: 400;
      font-size: 12px;
      margin: 0px;
    }
  }
}