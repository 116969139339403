/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .filter-container {
      .filter-button {
        background-color: getThemeValue("color__copy--medium");

        &.btn-tertiary {
          color: getThemeValue("color__brand--contrast");
        }

        &__active,
        &:active,
        &:hover {
          background-color: getThemeValue("color__brand--medium");
        }
      }
    }
  }
}

///////////////////////
/// COMPONENT STYLE ///
///////////////////////
.filter-container {
  display: flex;
  width: fit-content;

  .filter-text {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: fit-content;

    p {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 0.5rem;
    }
  }

  .filter-button {
    margin: 12px;
    border-radius: 14px;
  }
}

// we have to adjust the search bar styling only if a filter is section is there
.search__searchbar {
  margin-bottom: 0.25rem;
}

.filter-section {
  margin-bottom: 0.75rem;
}