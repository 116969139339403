///////////////
//// THEME ////
///////////////
.theme {
  @include theme {
    .student-tools {
      .tool-divider {
        border-right: 1px solid getThemeValue("color__grey");
      }

      .more-actions-popup-container {
        .__selected-speed {
          border: 2px solid getThemeValue("color__brand--medium");
          background-color: getThemeValue("color__brand--medium");
          color: getThemeValue("color__background--light");
        }
      }
    }
  }
}


///////////////////////
//// STUDENT TOOLS ////
///////////////////////

.student-tools {
  z-index: map-get($z-indexes, "toolBar");
  position: relative;
  transition: all 1s ease 0.1s;
  left: 50%;
  width: fit-content;
  transform: translate(-50%, 0);

  .tool-divider {
    height: 100%;
    margin: 0 8px;
  }

  .mode {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
      padding: 0;
      font-weight: 800;
      font-size: 16px;
    }
  }

  .focused,
  .focused-dark,
  .exploring,
  .exploring-dark {
    width: 100px;

    svg {
      width: 100% !important;
    }
  }

  &.inactive {
    left: 92px;
    transition: all 1s ease 0.1s;
    width: 50px;
  }
  .more-actions-popup-container {
    position: absolute;
    left: -124px;
    top: 0;
    width: fit-content;
    height: auto;
    transition: opacity 0.5s ease-in-out;
    transition-delay: 1.3s;
    opacity: 1;
   
    @include breakpoint("large+") {
     // top: -37px;
    }
    .children {
      height: fit-content !important;
      
    }
    &.hidden {  
      opacity: 0;
    }
    .btn-secondary {
      margin: 5px;
    }
  }
}

#student-tools__more-actions__captions{
  .icon-button-wrapper{
    height: 32px;
    .icon-button{
      height: 100%;
    }
    .icon {
      height: auto;
    }
  }
}
