.more-actions-wrapper {
  position: relative;
}

.more-actions-container {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
  z-index: map-get($z-indexes, "more-actions");
  width: fit-content;
  position: absolute;
  padding: 10px;
  margin: 0 auto;
  gap: 0;

  &-children {
    flex-shrink: 0;
    width: 100%;

    .more-actions-item {
      width: 100%;
      margin: 4px;

      .btn-container {
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
  }

  &.__going-down {
    top: $icon-button__tool-bar;
  }

  &.__going-up {
    bottom: 0;
  }

  &.__going-right {
    left: $icon-button__tool-bar;
  }

  &.__going-left {
    right: $icon-button__tool-bar;
  }
}
