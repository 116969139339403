@import "../variables.scss";
@import "../mixins.scss";

.participant-container {
  z-index: map-get($z-indexes, "video");

  display: flex;
  align-items: center;
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  height: 100px;
  min-width: 150px;
  max-width: 700px;

  .children {
    z-index: map-get($z-indexes, "video");

    // this will be the individual videos
  }

  & > div {
    max-width: 150px;
    margin: 0px 4px;
    border-radius: 4px;
    z-index: map-get($z-indexes, "video");
  }

  .close__wrapper {
    position: absolute;
    top: 26px;
    right: 15px;
    width: 24px;
  }
}
