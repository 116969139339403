/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .toolbar {
      transition: color 0.25s, background-color 0.25s ease 0.1s;
      color: getThemeValue("color__copy");
      background-color: getThemeValue("color__background--light");
  
      .children {
        &.vertical {
          .divider {
            width: $divider-height;
            border-bottom: 1px solid getThemeValue("color__grey");
          }
        }
  
        &.horizontal {
          .divider {
            width: $divider-height;
            border-right: 1px solid getThemeValue("color__grey");
          }
        }
      }
  
      // Icons in the toolbar have the same hover and selected styling
      .icon {
  
        &.--selected,
        &:hover {
          transition: all 0.1s linear 0.1s;
          background: getThemeValue("color__grey--opacity");
        }
      }
    }
  }
}


////////////////
/// TOOL BAR ///
////////////////

.toolbar {
  @include transition();
  z-index: map-get($z-indexes, "toolbar");
  display: flex;
  isolation: isolate;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  justify-content: flex-end;
  align-items: center;
  height: fit-content;
  width: fit-content;

  &.active {
    @include transition();
    padding: 5px;
  }

  &.inactive {
    @include transition();

    &.horizontal {
      padding: 5px 0;
    }

    &.vertical {
      padding: 0 5px;
    }
  }

  &.vertical {
    width: $toolbar-height;
    height: auto;
    flex-direction: column;

    // Icon button selected and hover styling in vertical toolbar
    .icon-button-wrapper {
      width: 48px;
      height: 48px;

      .icon-button {
        .icon {
          display: flex;
          justify-content: center;
          transition: all 0.1s linear 0.1s;
          width: 40px;
          height: 40px;

          &.--selected,
          &:hover {
            transition: all 0.1s linear 0.1s;
            width: 40px;
            height: 40px;
            border-radius: 4px;
          }
        }
      }
    }

    .toolbar__min-max-toggle {
      svg {
        height: 40px;
      }
    }
  }

  &.horizontal {
    height: $toolbar-height;
    flex-direction: row;

    // Icon button selected and hover styling in horizontal toolbar\\
    .icon-button-wrapper {
      width: 48px;
      height: 48px;

      .icon-button {
        .icon {
          display: flex;
          align-items: center;
          transition: all 0.1s linear 0.1s;
          width: 40px;
          height: 40px;
          justify-content: center;

          &.--selected,
          &:hover {
            display: flex;
            align-items: center;
            transition: all 0.1s linear 0.1s;
            width: 40px;
            height: 40px;
            border-radius: 2px;
          }
        }
      }
    }
  }

  .children {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    &.vertical {
      flex-direction: column;

      &>.icon-button-wrapper, .more-actions-button {
        svg {
          height: 40px;
        }
      }
    }

    &.horizontal {
      flex-direction: row;
      width: 100%;
    }

    &.active {
      visibility: visible;
      opacity: 1;
    }

    &.inactive {
    }

    &.minimized {
      width: 0;
      opacity: 0;
      display: none;
    }
  }
}