/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .engagement-tools-container {
      background-color: getThemeValue("color__background--light");

      &__expanded,
      .engagement-chat {
        border-right: 1px solid getThemeValue("color__grey");
      }
    }
  }
}

///////////////////////
/// COMPONENT STYLE ///
///////////////////////
.engagement-tools-container {
  position: fixed;
  z-index: map-get($z-indexes, "engagement");
  right: 8px;
  top: 50%;
  width: 48px;
  height: auto;
  transform: translate(0, -50%);
  transition: 0.5s;
  display: flex;
  border-radius: 6px;
  &__left {
    width: calc(100% - 48px);
  }
  &__right {
    width: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .toolbar .children.vertical {
      justify-content: flex-start !important;
    }
  }
  &__expanded {
    width: 400px;
    height: 543px;

    &--right {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .engagement-chat {
    box-shadow: none !important;
    width: 352px !important;
    &.container.chat-container {
      z-index: 0 !important;
      border-radius: 0.375rem 0 0 0.375rem !important;
      height: 100%;
    }
    .text-input-container {
      margin: 12px;
    }
  }
}

// why seperate this out? It didn't want to work in the & section...
.engagement-tools-container__expanded--right {
  &.toolbar {
    box-shadow: none !important;
  }
}
