@include breakpoint("xsmall+") {
  .modal_footer_section {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    display: flex;
    justify-content: space-evenly;
    align-content: baseline;
  }
}

@include breakpoint("medium+") {
  .modal_footer_section {
    height: 10%;
    position: relative;
    top: initial;
    left: initial;
    transform: none;
    width: 100%;
    align-content: center;
  }

}
