/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .icon {
      .tool-tip {
        background-color: getThemeValue("color__copy");
        color: getThemeValue("color__contrast");
  
        &.top {
          &:after {
            border-color: getThemeValue("color__copy") transparent transparent transparent;
          }
        }
  
        &.bottom {
          &:after {
            border-color: transparent transparent getThemeValue("color__copy") transparent;
          }
        }
  
        &.left {
          &:after {
            border-color: transparent transparent transparent getThemeValue("color__copy");
          }
        }
  
        &.right {
          &:after {
            border-color: transparent getThemeValue("color__copy") transparent transparent;
          }
        }
  
        &.none {
          display: none;
        }
      }
  
      &:hover .tool-tip {
        transition: visibility 0.1s ease-in-out 0.1s;
        visibility: visible;
      }
  
      //////////////////////////////////////
      /// hover / active states for svgs ///
      //////////////////////////////////////
      .styled-SVG-icon.--no-hover-pointer {
        cursor: default;
      }
    }
  }
}

///////////////
//// ICONS ////
///////////////

.icon {
  position: relative;
  display: inline-block;

  .tool-tip {
    visibility: hidden;
    width: auto;
    text-align: center;
    border-radius: 4px;
    padding: 8px 12px;
    font-family: $font-family__primary;
    z-index: map-get($z-indexes, "tool-tips");
    width: max-content;
    max-width: 225px;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    padding: 8px 12px;
    position: absolute;

    // Styling for the caret that points from the tool tip to the icon
    &:after {
      content: "";
      position: absolute;
      border-width: 5px;
      border-style: solid;
    }

    // Positioning and offset for the tool tip direction options
    &.top {
      bottom: 110%;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);

      &:after {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
      }
    }

    &.bottom {
      top: 110%;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);

      &:after {
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
      }
    }

    // Offsets for when tooltip goes off edge of screen
    &.top,
    &.bottom {
      &.l25 {
        left: calc(50% - 25px);

        &:after {
          left: calc(50% + 25px);
        }
      }

      &.l50 {
        left: calc(50% - 50px);

        &:after {
          left: calc(50% + 50px);
        }
      }

      &.l75 {
        left: calc(50% - 75px);

        &:after {
          left: calc(50% + 75px);
        }
      }

      &.l100 {
        left: calc(50% - 100px);

        &:after {
          left: calc(50% + 100px);
        }
      }

      &.r25 {
        left: calc(50% + 25px);

        &:after {
          left: calc(50% - 25px);
        }
      }

      &.r50 {
        left: calc(50% + 50px);

        &:after {
          left: calc(50% - 50px);
        }
      }

      &.r75 {
        left: calc(50% + 75px);

        &:after {
          left: calc(50% - 75px);
        }
      }

      &.r100 {
        left: calc(50% + 100px);

        &:after {
          left: calc(50% - 100px);
        }
      }
    }

    &.left {
      top: 50%;
      right: 110%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);

      &:after {
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
      }
    }

    &.right {
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      left: 110%;

      &:after {
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        right: 100%;
      }
    }

    &.none {
      display: none;
    }
  }

  &:hover .tool-tip {
    visibility: visible;
  }
}