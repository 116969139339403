/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .pagewrapper {
      color: getThemeValue("color__copy");
      background-color: getThemeValue("color__background--dark");
    }
  }
}
//////////////////////
//// PAGE WRAPPER ////
//////////////////////
.pagewrapper {
  height: 100%;
  transition: color 0.25s, background-color 0.25s ease 0.1s;
}
