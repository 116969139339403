/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .text-input-container {
      transition: color 0.25s, background-color 0.25s ease 0.1s;
      color: getThemeValue("color__copy--label");
      background-color: getThemeValue("color__background--light");
      .text-input-input {
        background-color: getThemeValue("color__background--light");
        color: getThemeValue("color__copy--label");
        border: 2px solid getThemeValue("color__copy--dark");
        &.disabled {
          color: getThemeValue("color__copy--label");
          background-color: getThemeValue("color__background--dark");
          border: getThemeValue("color__copy--label");
        }
        &.error {
          border: 2px solid getThemeValue("color__error");
          background-color: transparent;
        }
      }
      .text-input-label {
        &.disabled {
          color: getThemeValue("color__copy--label");
          background-color: getThemeValue("color__background--dark");
        }
        &.transform {
          background-color: getThemeValue("color__background--light");
        }
        &.error {
          color: getThemeValue("color__error");
        }
      }
      .it-error-message {
        color: getThemeValue("color__error");
        background-color: getThemeValue("color__background--light");
      }
    }
  }
}


/////////////
/// LABEL ///
/////////////

@include breakpoint("xsmall+") {
  .text-input-container {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    position: relative;
  }
}

.text-input-label {
  display: block;
  font-size: 1.25rem;
  margin-left: 1rem;
  margin-top: 0.65rem;
  position: absolute;
  transition: 0.25s all;
  -webkit-transition: 0.25s all;

  &.label-icon {
    padding-left: 20px;
  }

  &.transform {
    transform: translateY(-12px);
    -webkit-transform: translateY(-12px);
    transition: 0.25s all ease;
    -webkit-transition: 0.25s all ease;
    font-size: 0.875rem;
    margin-top: 0;
    margin-left: 0.8rem;
    padding: 4px;
    padding-left: 4px;
  }
}

///////////////////
/// INPUT VALUE ///
///////////////////

.text-input-input {
  border-radius: 4px;
  display: block;
  width: 100%;
  height: 3rem;
  padding-top: 24px;
  padding-bottom: 12px;
}

.input-icon {
  padding-left: 35px !important;
}

/////////////////////
/// ERROR MESSAGE ///
/////////////////////

.it-error-message {
  font-size: 0.9rem;
  margin-top: 0.25rem;
  margin-bottom: -1.25rem;
  padding-left: 17px;
  padding-right: 17px;
  text-align: end;

  &.multi-error {
    margin-top: 1.25rem;
  }
}

//////////////////////
/// ICON COMPONENT ///
//////////////////////

.text-input-icon {
  position: absolute;
  padding-top: 11px;
  padding-left: 7px;
}
