///////////////
//// THEME ////
///////////////
.theme {
  @include theme() {
    .replay-tools {
      .tool-divider {
        border-right: 1px solid getThemeValue("color__grey");
      }

      .more-actions-popup-container {
        .__selected-speed {
          border: 2px solid getThemeValue("color__brand--medium");
          background-color: getThemeValue("color__brand--medium");
          color: getThemeValue("color__background--light");
        }
      }
    }
  }
}

///////////////////////////////
//// REPLAY VIDEO CONTROLS ////
///////////////////////////////

.replay-tools {
  z-index: map-get($z-indexes, "toolBar");
  position: relative;
  transition: all 1s ease 0.1s;
  left: 50%;
  transform: translate(-50%, 0);
  justify-content: space-between;

  &.active {
    @include transition();
    width: 98%;
    min-width: unset;

    @include breakpoint("large+") {
      width: 88%;
      min-width: 940px;
    }

    @media (min-width: 990px) and (max-width: 1065px) {
      min-width: 940px;
      left: calc(50% + 20px);
      transform: translate(-50%, 0);
    }
  }

  .progress-bar-wrapper {
    display: flex;
    flex-direction: row;
    flex: 2 1 auto;

    .progress-container {
      margin: auto 16px;
      margin-left: 28px;

      @include breakpoint("medium+") {
        margin: auto;
        margin-right: 8px;
      }

      @include breakpoint("large+") {
        margin: auto 16px;
      }
    }
  }

  &__volume-panel {
    width: 48px;
    transition: width 0.3s ease;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;

    .progress-container {
      min-width: unset !important;
      max-width: 5vw !important;
      border-radius: 0px !important;
      margin-left: -6px;
      margin-right: 10px;
      height: 8px;
      transition: all 0.3s ease;

      /* Add a transition effect to the height property */
      &:hover {
        height: 12px;
        /* Increased height on hover */
        width: var(--w-hover);
        /* Change the width on hover */
      }
    }

    &.volume-visible {
      width: 10vw;
      transition: width 0.3s ease;
    }
  }

  .icon-button-wrapper {
    flex: 0 0 auto;
  }

  .tool-divider {
    min-height: 38px;
    margin: 5px;
  }

  .progress-container {
    border-radius: 0px !important;
    transition: width height 0.3s ease;
  }

  .mode {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
      padding: 0;
      font-weight: 800;
      font-size: 16px;
    }
  }

  .focused,
  .focused-dark,
  .exploring,
  .exploring-dark {
    width: 100px;

    svg {
      width: 100% !important;
    }
  }

  &.inactive {
    left: 28px;
    transition: all 1s ease 0.1s;
    width: 50px;
    border-radius: 6px;

    @include breakpoint("large+") {
      left: 78px;
    }
  }

  &.mobile-tools {
    z-index: map-get($z-indexes, "toolBar");
    position: relative;
    transition: all 1s ease 0.1s;
    left: 50% !important;
    transform: translate(-50%, 0);
    justify-content: space-between;

    .children.horizontal.active {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 98%;
      align-items: center;
      justify-content: flex-start;

      .tool-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &#swipe-grip{
          justify-content: center;
          height: 20px;
        };

        .progress-bar-wrapper {
          width: 100%;
        }
      }
    }
  }

  &__time-display {
    white-space: nowrap;
    margin: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: flex-end;

    @include breakpoint("large+") {
      padding-right: 16px;
    }

    @media (min-width: 1090px) {
      flex-direction: row;
    }

    &__divider {
      margin: 0;

      @media (min-width: 1090px) {
        margin: 0 2px;
      }
    }

    &__max,
    &__current {
      display: inline-block;
      width: 15px;

      &.minutes {
        width: 33px;
      }

      &.hours {
        width: 50px;
      }
    }

    &__current {
      text-align: initial;

      @media (min-width: 1090px) {
        text-align: end;
      }
    }
  }
  .more-actions-popup-container {
    position: absolute;
    left: -124px;
    top: 0;
    width: fit-content;
    height: auto;
    transition: opacity 0.5s ease-in-out;
    transition-delay: 1.3s;
    opacity: 1;
   
    @include breakpoint("large+") {
     // top: -37px;
    }
    .children {
      height: fit-content !important;
      
    }
    &.hidden {  
      opacity: 0;
    }
    .btn-secondary {
      margin: 5px;
    }   
  }
  .more-actions-container{
    transition: opacity 0.5s ease-in-out;
    transition-delay: 1.3s;
    opacity: 1;
    &.hidden {  
      opacity: 0;
    }
  }
}


.mobile-tools__chapters.--portrait.--replay {
  width: 100%;
  height: 100%;

}

.mobile-tools__chapters.--landscape.--replay {
  width: 50%;

  .chapter-list__preview {
    flex-direction: column;
  }
}

#replay-tools__more-actions__captions{
  .icon-button-wrapper{
    height: 32px;
    .icon-button{
      height: 100%;
    }
    .icon {
      height: auto;
    }
  }
}

.closed-captions__line{
  &.large {
    font-size: large;
  }
  &.larger {
    font-size: larger;

  }
  &.x-large {
    font-size: x-large;

  }
  &.xx-large {
    font-size: xx-large;

  }
}