.class-session {
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  border-bottom: 1px solid $color__grey;
  gap: 1rem;
  min-height: 3.5rem;
  align-items: center;

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__simple-view {
    margin-left: 1rem;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;

    &--play-buttons {
      flex-grow: 1;

      .btn-container {
        width: fit-content;
      }
    }

    .icon-button-wrapper,
    .btn-container {
      margin: 0 0.25rem;
    }
    &.--switch {
      min-width: 200px;
    }
  }

  &__edit-form {
    min-height: 425px;
  }

  .class-form {
    border-bottom: unset;
    margin-bottom: unset;
    padding-bottom: unset;
    max-width: 450px;
  }
}

.class-session__pop-up {
  &--header {
    font-size: 1.7rem;
    margin-bottom: 0;
  }
  &--subheader {
    font-size: 0.9rem;
    display: block;
  }
}

.react-calendar {
  border-radius: 0.4rem;
}

.react-datetime-picker__wrapper {
  border-radius: 0.25rem;
}
