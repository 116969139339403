.search__searchbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  .text-input-container {
    width: 80%;
    margin: 0;
    margin-right: 1rem;
  }
  .btn-container {
    width: 20% !important;
  }
}
