@include breakpoint("xsmall+") {

.delete-board-heading {
    font-size: $font-size__header--mobile;
}
.delete-board-content {
    min-height: 8rem;
    min-width: 23.475rem;
    padding: 30px;
    font-size: $font-size__sub-header;
}
.delete-board-footer__buttons {
    width: 40%;
    &.--primary button {
        height: 3rem;
    }
}
}

@include breakpoint("medium+") {
    .delete-board-heading {
        font-size: $font-size__sub-header;
    }
    .delete-board-content {
        font-size: $font-size__body;
    }
    }