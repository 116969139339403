////////////////
/// TAB LIST ///
////////////////
.tab-list {
  .btn-tertiary {
    width: 100%;
  }

  &__item--active.btn-tertiary {
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  @include breakpoint("xsmall+") {
    display: flex;
    flex-direction: row;
    gap: 10px;

    button {
      width: 100%;
    }
  }

  @include breakpoint("medium+") {
    flex-direction: column;
  }
}
