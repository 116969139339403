.panorama-image {
  &__preview {
    overflow: hidden;
  }

  .art-object {
    margin-bottom: 10px;
  }

  .tab-content {
    height: 522px;
  }
}