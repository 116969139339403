/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .join,
    .demo {
      color: getThemeValue("color__copy");
      background-color: getThemeValue("color__background--dark");
      .joinform {
        color: getThemeValue("color__copy");
        background-color: getThemeValue("color__background--light");
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      }
      &__browser-error{
        background-color: getThemeValue("color__brand--medium");
        color: $white;
      }
      &__browser-error--mobile {
        background-color: $color__admin-blue;
      }
    }
  }
}


////////////
/// JOIN ///
////////////
.join,
.demo {
  transition: color 0.25s, background-color 0.25s ease 0.1s;
  height: 100vh;
  @include breakpoint("medium+"){
    margin-top: 6.25rem;
  }
  .joinform {
    transition: color 0.25s, background-color 0.25s ease 0.1s;
  }
  &__browser-error{
    width: 100%;
    display: flex;
    vertical-align: middle;
    justify-content: center;
  }
  &__browser-error--mobile {
    margin-bottom: 1rem;
  }
}

@include breakpoint("xsmall+") {
  .joinform-container {
    font-family: $font-family__primary;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: inherit;
    
    .joinform {
      padding: 16px;
      border-radius: 1rem;
    }

}
}

@include breakpoint("medium+") {
  .joinform {
    padding: 16px;
    border-radius: 1rem;
    min-width: 400px;
    margin-bottom: 10%;
    margin-top: 17vh;
  }

  .joinform-container{
    align-items: center;
    max-height: initial;
  }

  .settings-container.visible {
    padding-bottom: 2vh;
  }

  .joinForm-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
  }
}

////////////////
// ANIMATIONS //
////////////////

.joinform-container.invisible {
  opacity: 0;
  transform: translateY(100vh);
  -webkit-transform: translateY(100vh);
  transition: opacity 1s ease, transform 1s ease;
  -webkit-transition: opacity 1s ease, transform 1s ease;
  animation-duration: 1s;
}

.settings-container.invisible {
  opacity: 0;
  transform: translateY(1000px);
  -webkit-transform: translateY(1000px);
}
.settings-container.visible {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  transition: opacity 1s ease, transform 1s ease;
  -webkit-transition: opacity 1s ease, transform 1s ease;
  animation-duration: 1s;
}
