.replay-preview-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 8px;
  position: relative;
  height: 100%;
  width: 100%;

  .slide__preview {
    height: 75px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    &__iiif{
      width: 90px;
    }
  }

  .slide__title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;

    &--text {
      font-weight: 500; 
      font-size: 14px; 
      margin: 0px;
    }

    &--time {
      font-weight: 400; 
      font-size: 12px; 
      margin: 0px;
    }
  }
}