///////////////
//// THEME ////
///////////////
.theme {
  @include theme() {
    .nav-header {
      $self: &;
      transition: color 0.25s, background-color 0.25s ease 0.1s;
      background-color: getThemeValue("color__background--dark");
      // border-bottom: 1px solid getThemeValue("color__brand--medium");
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);

      a {
        text-decoration: none;

        &:hover,
        &:active {
          text-decoration: underline;
        }
      }

      .nav__link {
        &.--active {
          text-decoration: underline;
        }

        // Active page
        &[aria-current] {
          text-decoration: underline;
        }

        &:focus:not(.focus-ring) {
          border-bottom-color: currentColor;
        }

        &:hover {
          text-decoration: underline;
        }

        &:focus:hover {
          color: currentColor;
        }
      }
    }
  }
}

///////////////////////
/// COMPONENT STYLE ///
///////////////////////
.nav-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media print {
    display: none;
  }

  a {
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}

////////////////////
//// PAGE LINKS ////
////////////////////
.nav__link {
  border-bottom: 2px solid transparent;
  color: currentColor;
  margin: 2px 10px 0;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;

  span {
    margin-top: -3px;
    margin-right: 3px;
  }

  &.--active {
    text-decoration: underline;
  }
}

////////////////
//// HEADER ////
////////////////

.nav-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 120px;
  padding-left: 20px;
  padding-right: 20px;

  @include breakpoint("small+") {
    font-size: 12px;
  }

  @include breakpoint("medium+") {
    font-size: 15px;

    .logged-in-as {
      font-size: 12px;
    }
  }

  @include breakpoint("large+") {
    font-size: 22px;

    .logged-in-as {
      font-size: 18px;
    }
  }

  @include breakpoint("medium+") {
    height: 128px;
    width: auto;
    padding-right: 40px;
    padding-left: 40px;
    position: absolute;
    justify-content: space-between;
    top: 0;
    right: 0;
    left: 0;
    z-index: map-get($z-indexes, "modal");
  }

  /////////////////
  // BARNES LOGO //
  /////////////////

  &__logo {
    height: $divider-height;

    @include breakpoint("medium+") {
      height: 45px;
    }

    @include breakpoint("large+") {
      height: 50px;
    }

    &.centennial-logo {
      height: 40px;

      @include breakpoint("medium+") {
        height: 48px;
      }

      @include breakpoint("large+") {
        height: 54px;
      }
    }
  }

  &__log-out,
  &__log-in {
    text-align: right;
    display: flex;
    flex-direction: column;
  }

  .nav {
    &__options {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
    }

    &__theme-toggle {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}