/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .checkbox-container input:checked ~ .checkmark {
      border: solid 2px getThemeValue("color__button");
      background-color: getThemeValue("color__brand--medium");
    }
    
    .checkbox-container:hover input ~ .checkmark {
      border: solid 2px getThemeValue("color__button");
      background-color: getThemeValue("color__brand--hover");
    }
    
  }
}

////////////////
/// CHECKBOX ///
////////////////

.checkbox-container {
  @include transition();
  height: 30px;
  width: 30px;
  border-radius: 50%;
  outline: none;
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-appearance: none;
  appearance: none;
  -ms-user-select: none;
  user-select: none;
  margin: 4px;

  input {
    @include transition();
    position: absolute;
    cursor: pointer;
    appearance: none;
    border-radius: 100%;
    height: 100%;
    width: 100%;
    z-index: map-get($z-indexes, "more-actions");
  }
}

.checkbox-label {
  margin-left: 0.5rem;
  margin-top: -2rem;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.checkbox-wrapper {
  width: fit-content;
  &__labeled {
    width: fit-content;
  }
}
.checkmark {
  @include transition();
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 4px;
  width: 25px;
  height: 25px;
  border: 2px $white solid;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  background-color: $white;
}

.checkbox-container input:focus {
  outline: max(2px, 0.15em) solid $focus-blue;
  outline-offset: max(2px, 0.15em);
  right: -5px;
  top: -2px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 4px;
  height: 9px;
  border: solid $white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
