///////////////
//// THEME ////
///////////////
.theme {
  @include theme () {
    .recording-details__modal {
      .recording-details__table__row {
        border-top: 1px solid getThemeValue("color__copy--medium")
      }
    }
  }
}

/////////////////////////////////
//// RECORDING DETAILS MODAL ////
/////////////////////////////////
.recording-details {
  &__modal {
    min-width: 900px;
    width: fit-content;
  }

  &__table {
    border-spacing: 0.5rem;
    border-collapse: collapse;

    &__cell {
      text-align: center;
      vertical-align: middle;
      padding: 24px 0;
    }
  }



  &__date {
    white-space: nowrap;
    font-weight: bolder;
  }

  &__meta {
    display: flex;
    flex-direction: column;
    white-space: wrap;
  }

  &__status {
    text-align: left;
  }

  &__replace-recording {
    margin-top: 1rem;
  }
 }