/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .instructions {
      .instructions-heading {
        color: getThemeValue("color__brand--medium");
      }
  
      .instructions-subheading strong {
        color: getThemeValue("color__brand--medium");
      }
  
      .instructions-content__keyboard--instructions {
        border-bottom: 1px solid getThemeValue("color__copy--light");
      }
    }
  }
}


//////////////////////
//// INSTRUCTIONS ////
//////////////////////

@include breakpoint("xsmall+") {
  .instructions-container {
    text-align: center;
    .instructions-body{
      p {
        line-height: 1.5; /* Space-and-a-half line spacing */
        margin: 1.5em auto; /* Paragraph spacing 1.5 times larger than line spacing */
          }
    }
    .replay-instructions-heading{
      margin: 1rem auto 0;
     }
     .modal_footer_section {
      flex-wrap: wrap;
    }
    .instructions-toggle-section{
      .toggle-container{
        display: flex;
        justify-content: center;
      }
    }
    .toggle-container{
      display: flex;
      justify-content: center;
    }
  }

  .instructions-heading {
    font-size: 2srem;
    margin-top: 0;
    margin-bottom: 0;
    width: fit-content;
    text-align: left;
  }

  .instructions-subheading {
    width: fit-content;
    text-align: center;
    margin: auto;
    p {
      text-align: left;
    }
    &__no-display{
      display: none;
    }
  }

  .instructions-footer{
    &__skip-to-class{
      margin: 10px ; 
      width: 100%;
    }
  }

  .instructions-content {
    &__keyboard {
      display: flex;
      flex-direction: column;
      margin: 1rem auto;
      width: fit-content;
      text-align: start;
    }
    &__instructions-column {
      padding: 0 16px;
    }
    &__commands-wrapper {
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: center;
      gap: 8px;
      height: fit-content;
      &.--landscape {
        flex-direction: row;
      }
    }
    &__instructions-commands {
      margin-top: 1rem;
      width: 50%;
      height: 100%;
      .icon {
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
    }
    &__instruction-text {
      display: block;
    }
    &__keyboard--instructions {
      display: block;
      width: 100%;
      padding: 4px;
    }
    &__keyboard--keypress {
      font-weight: 700;
    }
  }
  .btn-secondary.standard.instructions-secondary {
    white-space: normal;
  }

  .--single-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 0;
  }
}

@include breakpoint("medium+") {
  .instructions-content {
    &__instructions-column {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__instruction-text {
      text-align: center;
    }
  }
  .instructions-footer{
    &__skip-to-class{
      margin-top: 10px ; 
    }
  }
  .instructions-container {
    width: 80vw;
    height: 80vh;
    max-width: 53.125rem;
  }
}

@include breakpoint("large+") {

  .instructions-content {
    display: flex;
    margin: 1rem auto;
    &__keyboard {
      margin: 1rem auto;
      text-align: start;
    }
    &__instructions-column {
      padding: 0 16px;
      width: 50%;
    }
    &__instructions-column.mobile {
      width: 100%;
    }
    &__commands-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
    &__instructions-commands {
      margin: 1rem;
    }
    &__keyboard--instructions {
      display: block;
      width: 100%;
      padding: 4px;
    }
  }
}

.instructions-content {
  &__wrapper {
    margin-bottom: 1.5rem;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
}
