/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .replay-explore-toggle {
      .tool-divider {
        border-right: 1px solid getThemeValue("color__grey");
      }
    }
  }
}

//////////////////////////
/// REPLAY EXPLORE TOGGLE ///
//////////////////////////
.replay-explore-toggle {
  width: 250px;
  flex-direction: row;
  padding: 10px;
  .horizontal {
    height: 38px !important;
    width: 200px;
    flex-direction: row;
  }
  .tool-divider {
    height: 38px;
    margin: 0 8px;
  }
  &__subcontainer {
    text-align: center;
    width: 100px;
  }
  &__input {
    transform: rotate(180deg);
    padding-top: 22px;
  }
  &__text {
    font-weight: bold;
  }
  &__icon {
    padding-top: 2px;
    padding-left: 8px;
  }
  .replay-explore-toggle__exploring-focusing {
    width: 100px;
    svg {
      width: 100%;
    }
  }
}
