/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .upload-modal {
      #upload-modal__form {
        .upload-modal__label {
          border-color: getThemeValue(color__copy--dark);
          background-color: getThemeValue(color__background--light);
        }
      }

      &__error {
        color: getThemeValue(color__error);
      }

      #permission {
        color: getThemeValue(color__copy--label);
        background-color: getThemeValue(color__background--light);
        border: 2px solid getThemeValue(color__copy--dark);
      }
    }
  }
}

//////////////////////////
/// UPLOAD IMAGE MODAL ///
//////////////////////////
.upload-modal {
  display: flex;
  flex-direction: column;
  width: 692px;

  &.container {
    height: 75vh;
  }

  &__body {
    flex-grow: 2;
    width: 625px;
  }

  &__footer {
    width: 625px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }

  #upload-modal__form {
    width: 100%;
    height: 100%;

    #upload-modal__input {
      display: none;
    }
  }

  &__loading {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 28px;

    &--message {
      font-size: larger;
      margin: 0 auto 24px;
    }

    &--simple-bar {
      margin-right: 16px;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    cursor: pointer;
    height: calc(100% - 2rem);
    width: calc(100% - 2rem);
    margin: 1rem;

    .simplebar-content {
      height: 100%;
    }

    &.drag-active {
      outline: max(2px, 0.15em) solid $focus-blue;
      outline-offset: max(2px, 0.15em);
    }

    &--simple-bar {
      overflow: auto;
      height: 100%;
      width: calc(100% - 5rem);
      margin: auto;
    }

    &--content {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-instructions {
        text-align: center;
        font-size: larger;
        font-weight: bold;
        width: 75%;
        margin: 20px auto;
      }

      &-selected {
        margin: 20px 0px;

        ul {
          list-style-type: none;

          li {
            margin-bottom: 12px;
          }
        }

        &__row {
          font-weight: bold;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;

          &.img-meta-form__row {
            margin-top: -24px;

            #permission {
              border-radius: 4px;
            }
          }
        }
      }
    }
  }

  &__error {
    margin-bottom: 20px;

    ul {
      li {
        margin-bottom: 8px;
      }
    }

    &--file {
      font-weight: bold;
    }
  }
}

///////////////////////
/// UPLOAD PROGRESS ///
///////////////////////
.upload-progress {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: fit-content;
  gap: 8px;
  margin-bottom: 20px;

  &__title {
    font-size: larger;
    font-weight: bold;
  }

  &__details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}