/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .container {
      transition: color 0.25s, background-color 0.25s ease 0.1s;
      color: getThemeValue("color__copy");
      background-color: getThemeValue("color__background--light");
    }
  }
}

///////////////////
//// CONTAINER ////
///////////////////

.container {
  z-index: map-get($z-indexes, "container");
  display: flex;

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);

  width: fit-content;
  height: fit-content;

  padding: 24px;
  margin: 24px 0px;
  isolation: isolate;

  backdrop-filter: blur(10px);

  border-radius: 4px;

  flex: none;
  order: 0;
  flex-grow: 0;
  justify-content: center;
  align-items: center;

  &.active {
    @include transition();
    visibility: visible;
    opacity: 1;
    height: auto;
  }

  &.dismissed {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: all 0.01s ease 0.1s display 1s;
  }

  .children {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.active {
      @include transition();
      visibility: visible;
      opacity: 1;
    }

    &.dismissed {
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: all 0.01s ease 0.1s display 1s;
    }
  }

  .close__wrapper,
  .chat-container__chat-close-button {
    .icon.dismiss {
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }
}
