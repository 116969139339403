.meeting-options-container {
  width: 100%;
  margin-top: 0;
  box-shadow: none;

  .meeting-options-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__one-line{
      display: flex;
      align-items: center;
      justify-content: space-between;    
      width: 100%;
    }
    
    &__h1{
      margin-top: 0;
      font-size: 1.5rem;
      align-self: baseline; 
    }
    &__mute-lock-container, &__video-lock-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0.5rem;

      #switch-mute-lock, #switch-video-off-lock {
        transform: rotate(180deg);
        margin-bottom: 0;
        &.switch--active::before {
          background-color: $action-green;
        }
      }
    }
    &__subtext {
      font-size: 0.9rem;
      margin-top: 0.25rem;
      &--error{
        display: inline-block;
        color: $action-red;
      }
    }
    &__heading{
      margin: 0 0 0.5rem 0;
    }

  }
}
