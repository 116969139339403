.video-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex: 0 0 auto;

    @include breakpoint("medium+") {
        width: fit-content;
    }

    // Uncomment to renenable overlay video controls on mobile
    // &.mobile-tools{
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     left: 0;
    //     bottom: 0;
    //     height: 100%;
    //     width: 100%;
    //     background: rgba(0, 0, 0, 0.4);
    //    .icon-button-wrapper{
    //     margin: 5vw;
    //    }
    // }
}