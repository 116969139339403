@import "../../variables.scss";

.art-object {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation: fadeInAnimation ease 1s;
  -webkit-animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;

  @include breakpoint("medium+") {
    .art-object-image {
      width: 100%;
      height: auto;
    }

    &:hover,
    &:focus {
      .art-object-overlay {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}