/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .style-guide {
      color: getThemeValue("color__copy");
      background-color: getThemeValue("color__background--dark");
  
      .colors {
        .color {
          color: getThemeValue("color__copy")
        }
  
        // Copy Colors
        #copy {
          color: getThemeValue("color__copy");
        }
        #copy--light {
          color: getThemeValue("color__copy--light");
        }
        #copy--medium {
          color: getThemeValue("color__copy--medium");
        }
        #copy--dark {
          color: getThemeValue("color__copy--dark");
        }
        #copy--aws {
          color: getThemeValue("color__copy--aws");
        }
        #copy--label {
          color: getThemeValue("color__copy--label");
        }
        
        //  Brand Colors
        #brand--light {
          background-color: getThemeValue("color__brand--light");
        }
        #brand--medium {
          background-color: getThemeValue("color__brand--medium");
        }
        #brand--dark {
          background-color: getThemeValue("color__brand--dark");
        }
  
        // App Colors
        #grey {
          background-color: getThemeValue("color__grey");
        }
        #contrast {
          background-color: getThemeValue("color__contrast");
        }
        #grey--opacity {
          background-color: getThemeValue("color__grey--opacity");
        }
        #error {
          background-color: getThemeValue("color__error");
        }
        #admin-blue {
          background-color: $color__admin-blue;
        }
        #admin-blue--light {
          background-color: $color__admin-blue--light;
        }
        #green {
          background-color: $color__green;
        }
        #yellow {
          background-color: $color__yellow;
        }
        #video--icons {
          background-color: getThemeValue("color__video--icons");
        }
  
        //  Background Colors
        #background--light {
          background-color: getThemeValue("color__background--light");
        }
        #background--medium {
          background-color: getThemeValue("color__background--medium");
        }
        #background--grey {
          background-color: getThemeValue("color__background--grey");
        }
        #background--dark {
          background-color: getThemeValue("color__background--dark");
        }
        #background--shadow {
          background-color: getThemeValue("color__background--shadow");
          color: getThemeValue("color__copy--light")
        }
        #background--canvas {
          background-color: getThemeValue("color__background--canvas");
        }
  
        //  Button Colors
        #button {
          background-color: getThemeValue("color__button");
        }
        #button--hover {
          background-color: getThemeValue("color__button--hover");
        }
        #button--border {
          background-color: getThemeValue("color__button--border");
        }
        #button--calendar {
          background-color: getThemeValue("color__button--calendar");
        }
  
        // Border Colors
        #border--dark {
          border: getThemeValue("color__border--dark");
        }
        #border--input {
          border: getThemeValue("color__border--dark");
        }
  
        // Hover
        #nav--hover {
          background-color: getThemeValue("color__nav--hover");
        }
      }
    }
  }
}


///////////////////
/// STYLE GUIDE ///
//////////////////

.style-guide {
  transition: color 0.25s, background-color 0.25s ease 0.1s;
  //////////
  // PAGE //
  //////////
  .page {
    max-width: 2000px;
    padding: #{map-get($pagePadding, "xsmall")};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    margin: auto;
    margin-top: 6.25rem;

    @include breakpoint("medium+") {
      padding: #{map-get($pagePadding, "medium")};
      padding-top: #{map-get($pagePadding, "xsmall")};
    }

    @include breakpoint("large+") {
      padding: #{map-get($pagePadding, "large")};
      padding-top: #{map-get($pagePadding, "small")};
    }

    @include breakpoint("xlarge+") {
      padding: #{map-get($pagePadding, "xlarge")};
      padding-top: #{map-get($pagePadding, "medium")};
    }
  }

  .colors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;

    .color {
      height: 6rem;
      width: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
    }
  }

  li,
  ul {
    list-style: none;
  }
}
