/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .panorama {
      .pnlm-info-box {
        background-color: getThemeValue("color__brand--medium") !important;
        color: getThemeValue("color__copy--dark") !important;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2) !important;
      }

      // loading pop-up
      .pnlm-load-box {
        background-color: getThemeValue("color__brand--medium") !important;
        color: getThemeValue("color__copy--dark") !important;
      }

      .pnlm-container {
        background: getThemeValue("color__copy--dark") url("../../../public/icons/barnesLogo.svg") no-repeat fixed 50% !important;
        // Background size styling needs to be defined along with background for it to be set properly
        background-size: 300px 100px !important;
      }
    }
  }
}


////////////////
/// PANORAMA ///
////////////////

// hiding a goofy Pannellum pop up that happens when you right click on the pano
.pnlm-about-msg {
  visibility: hidden;

  a {
    visibility: hidden;
  }
}

// panorama container, sets the background and size
.pnlm-container {
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  cursor: default;
  width: 100%;
  height: 100%;
  font-family: $font-family__primary !important;
}

// error and info pop-up
.pnlm-info-box {
  border-radius: 6px !important;
  font-family: $font-family__primary !important;
  font-size: 1rem !important;
  opacity: 0.8 !important;
}

// loading pop-up
.pnlm-load-box {
  opacity: 0.8 !important;
}