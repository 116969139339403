///////////////
//// THEME ////
///////////////
.theme {
  @include theme() {
    .canvas-controls {
      transition: color 0.25s, background-color 0.25s ease 0.1s;
      color: getThemeValue("color__copy");
      background-color: getThemeValue("color__background--light");
  
      .slide__preview {
        background-color: getThemeValue("color__background--canvas");
  
        &-current {
          border: 2px solid getThemeValue("color__brand--medium");
        }
      }
  
      .canvas-controls__toolbar {
        border-bottom: 1px solid getThemeValue("color__grey");
  
        &-toggle {
          border-right: 1px solid getThemeValue("color__grey");
        }
      }
  
      .canvas-controls__toolbar-slide-nav__group:last-child {
        border-left: 1px solid getThemeValue("color__grey");
      }
    }
  }
}


/////////////////////////
//// CANVAS CONTROLS ////
/////////////////////////
.canvas-controls {
  z-index: map-get($z-indexes, "toolBar");
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  &__toolbar-toggle {
    z-index: map-get($z-indexes, "toolBar") + 1;
  }

  &__toolbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 5px;
    border-bottom: 1px solid $color__grey;

    @include breakpoint("medium+") {
      height: 48px;
      flex-direction: row;
      justify-content: space-between;
    }

    &-buttons {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }

    &-toggle {
      border-right: 1px solid $color__grey;
      padding-right: 5px;

      .feather-chevrons-up,
      .minimize-icon {
        transform: rotate(180deg);
      }
    }

    &-slide-nav {
      display: flex;
      flex-direction: row;
      width: 100%;

      @include breakpoint("medium+") {
        width: unset;
      }

      &__group {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 50%;

        @include breakpoint("medium+") {
          width: unset;
        }

        &:last-child {
          border-left: 1px solid $color__grey;
          padding-left: 5px;
          margin-left: 5px;
        }
      }
    }
  }

  &__explore-focus {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint("medium+") {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &__left-section {
    margin-left: 0;
    margin-right: auto;
    display: inherit;
  }

  &.minimized {
    height: 83px;
    transition: all 0.01s ease 0.1s;

    @include breakpoint("medium+") {
      height: 48px;
    }

    .canvas-controls {
      &__toolbar {
        border-bottom: none;
        transition: all 0.01s ease 0.1s;
      }

      &__slide-manager {
        display: none;
        transition: all 0.01s ease 0.1s;
        visibility: hidden;
      }
    }
  }
}

//////////////////////
/// slide previews ///
//////////////////////
.canvas-controls__slide-manager {
  display: flex;
  flex-direction: row;
  opacity: 1;
  visibility: visible;
  padding-bottom: 6px;
  gap: 8px;
  margin: 8px 10px 0px;
  height: 135px;

  // SimpleBar styling
  &__simplebar {
    height: 135px;
    overflow-y: hidden;
  }

  .slide__wrapper {
    position: relative;
  }

  // More actions
  .slide-actions {
    display: block;
    position: absolute;
    top: 0;
    right: 0;

    .slide-actions__more-actions {
      top: unset;
      bottom: unset;
      left: unset;
      right: unset;
      margin-left: $icon-button__slide-preview;
    }
  }

  .slide {
    max-width: 150px;
    margin-bottom: 8px;
    cursor: pointer;

    &.disabled {
      cursor: default;
    }

    &__preview {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(10px);
      border-radius: 4px;
      height: 90px;
      width: 150px;
      margin-bottom: 5px;

      &--threedee {
        display: block;
        width: 150px;
        height: 90px;
        overflow: auto;
      }
    }

    &__title {
      font-size: 0.85rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
}

///////////////////////
/// add board popup ///
///////////////////////
.add-a-board-popup-container .pop-up {
  height: 150px;
  opacity: 1;
  animation: fade 2s linear;
  -webkit-animation: fade 2s linear;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}