/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .helper-pop-up-container {
      color: getThemeValue("color__copy");
      background-color: getThemeValue("color__background--light");
    }

    .pop-up-arrow {
      &__down {
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 15px solid getThemeValue("color__background--light");
      }

      &__top {
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 15px solid getThemeValue("color__background--light");
      }

      &__left {
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 15px solid getThemeValue("color__background--light");
        align-self: center;
      }

      &__right {
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 15px solid getThemeValue("color__background--light");
        align-self: center;
      }
    }
  }
}

///////////////////////
/// COMPONENT STYLE ///
///////////////////////
.helper-pop-up-container {
  position: relative;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;

  &__close-wrapper {
    position: absolute;
    top: -4px;
    right: -4px;
    margin-bottom: 2rem;
  }

  &.fade-out {
    opacity: 1;
    animation: fadePop 1s linear;
    -webkit-animation: fadePop 1s linear;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }

  // Z-Index for alert popup
  &#alert {
    .helper-pop-up-content {
      z-index: 150;
    }
  }
}

.helper-pop-up-content {
  transition: color 0.25s, background-color 0.25s ease 0.1s;
  color: inherit;
  background-color: inherit;
  width: auto;
  height: auto;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  z-index: map-get($z-indexes, "tool-tips");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &.--absolute {
    position: absolute;
  }

  &.--fixed {
    position: fixed;
  }

  &__timed {
    opacity: 1;
    animation: fadePop var(--timer-fade-time) linear;
    -webkit-animation: fadePop var(--timer-fade-time) linear;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }

  &__center-width {
    left: 50%;
    transform: translate(-50%, 0);
  }

  &__message {
    margin: 0;
    font-size: 14px;
  }

  &__message--dismiss {
    margin-top: 1rem;
  }

  label {
    font-size: 14px;
    padding: 10px;
    min-width: 260px;
  }
}

.pop-up-arrow {
  position: absolute;
  width: 0;
  height: 0;

  &__down {
    bottom: -15px;
  }

  &__top {
    top: -15px;
  }

  &__left {
    left: -15px;
    align-self: center;
  }

  &__right {
    right: -15px;
    align-self: center;
  }
}

@keyframes fadePop {
  0% {
    visibility: visible;
    opacity: 1;
  }

  20% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}