/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .chat-live-area {
      &__chat-item {
        &:hover {
          background-color: getThemeValue("color__background--dark");
        }

        &.__deleted,
        &.__soft-banned {
          color: $color__copy--light;
        }
      }

      &__moderation {
        color: getThemeValue("color__copy");
        background-color: getThemeValue("color__background--light");
      }
    }
  }
}

.chat-container__chat-content {
  .chat-live-area {
    &__chat-item {
      &:hover {
        border-radius: 6px;
      }

      &.__deleted,
      &.__soft-banned {
        color: $color__copy--light;
      }
    }

    &__moderation {
      transition: color 0.25s, background-color 0.25s ease 0.1s;
    }

    &__chat-row {
      &.__chat-incoming {
        .chat-live-area__message-details {
          width: calc(100% - $attendee-initials-height - $chat-message-gap); // Subtract the width of the initials icon and the margin to the left of the message details to get correct container width

        }
      }

      &.__chat-outgoing {
        .chat-live-area__message-details {
          width: 100%;
        }
      }
    }

    &__message-data,
    &__chat-firstline {
      width: 100%;
    }

    &__chat-name {
      flex-grow: 1;
      flex-shrink: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__chat-time {
      flex-grow: 0;
      flex-shrink: 0;
      white-space: nowrap;
    }
  }
}

@include breakpoint("xsmall+") {
  .container {
    &.chat-container {
      position: relative;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      z-index: #{map-get($z-indexes, "modal-overlay")};
      gap: 0 !important;
      font-family: $font-family__primary;
      box-shadow: none;
    }
  }

  .chat-container__chat-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  .chat-focus-lock {
    width: 100%;
    height: 100%;
  }

  .chat-container {

    &__chat-header {
      flex-grow: 0;
    }

    &__chat-title {
      font-size: 2rem;
      margin-bottom: 0.75rem;
      margin-top: -0.25rem;
    }

    &__chat-close-button {
      position: absolute;
      top: 26px;
      right: 15px;
      width: 24px;
    }

    &__simplebar {
      flex-grow: 1;
    }

    &__chat-input {
      width: 100%;
      flex-grow: 0;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

      .text-input-container {
        width: 90%;
      }

      .icon-button {
        width: 10%;
      }
    }
  }

  .__chat-outgoing {
    text-align: end;
    align-items: flex-end;
  }

  .__chat-incoming {
    text-align: left;
    align-items: flex-start;
  }

  .chat-live-area {
    padding: 16px 0;
    overflow-x: clip;
    transition: all 0.25s ease-in-out;
    // Reduce width to move content from under SimpleBar scroll
    width: calc(100% - 16px);

    &__message-data {
      display: flex;
      &.__student, &.__replay{
        height: 30px;
        margin-bottom: -1rem;
      }
    }

    &__message-details {
      display: flex;
      flex-direction: column;
      margin-left: $chat-message-gap;
      height: fit-content;
      &--student{
        flex-direction: row;
      }
    }

    &__chat-item {
      display: flex;
      flex: row;
      padding: 0 12px;
      justify-content: center;
      gap: 8px;
      position: relative;

      &:hover,
      &.__mobile {
        .chat-live-area__moderation {
          visibility: visible;
        }
      }

      // Deleted and soft-banned message styling
      &.__deleted,
      &.__soft-banned {
        .chat-live-area__chat-name,
        .chat-live-area__chat-time {
          font-style: italic;
        }

        .chat-live-area__chat-message {
          text-decoration: line-through;
        }

        .chat-live-area__moderation-message {
          font-size: 0.75rem;
          vertical-align: top;
          display: inline-block;
          margin-top: 0.15rem;
        }
      }
    }

    &__moderation {
      visibility: hidden;
      margin: auto;
      display: flex;
      flex-direction: row;
      gap: 6px;
      @include box-shadow();
      z-index: map-get($z-indexes, "container");
      position: absolute;
      right: 2px;
      top: -2px;
    }

    &__chat-row {
      margin: 1rem 0;
      display: flex;
      flex-direction: column;
      width: 100%
    }

    &__chat-initials {
      &.__chat-outgoing {
        display: none;
      }
    }

    &__chat-message {
      font-size: 1rem;
      display: inline-block;

      &.__chat-incoming {
        margin-left: 2.25rem;
        display: inline-block;
        width: 85%;
      }

      &.__chat-outgoing {
        text-align: left;
      }
    }

    &__chat-firstline {
      display: flex;
    }
      &__chat-time {
        font-size: 1rem;
        vertical-align: top;
        display: inline-block;
        margin-top: -0.15rem;
        margin-left: $chat-message-gap;
        color: $color__copy--light;
      }
  
      &__chat-name {
        vertical-align: top;
        display: inline-block;
        font-size: 1.1rem;
        font-weight: 600;
        margin-top: -0.25rem;
      }
      &__chat-secondline {
        display: flex;
      }
 
 
    &__chat-orgname {
      font-size: .9rem;
      vertical-align: top;
      display: inline-block;
      margin-top: -0.1rem;
      color: $color__copy--light;
      font-style: italic;
  }
}
}

@include breakpoint("medium+") {
  .container {
    &.chat-container.active {
      font-family: $font-family__primary;
      @include box-shadow();
    }
  }

  .chat-container {
    &__chat-content {
      width: 300px;

      .text-input-container {
        margin: 0px !important;
      }
    }

    &__chat-title {
      padding-left: 12px;
      padding-bottom: 12px;
      margin-bottom: -0.2rem;
      margin-top: 0;
      font-size: 1.5rem;
    }

    &__chat-close-button {
      top: 8px;
      right: 2px;
    }

    &__chat-input {
      margin-top: 12px;
      flex-shrink: 0;
      height: 50px;
    }
  }

  .chat-live-area {
    &__chat-message {
      font-size: 1rem;
    }

    &__chat-name {
      font-size: 1rem;
      margin-top: -0.1rem;
    }

    &__chat-time {
      font-size: 0.75rem;
      margin-top: 0.1rem;
    }
  }
}