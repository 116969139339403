@import "../variables.scss";
@import "../mixins.scss";

/////////////
/// THEME ///
/////////////
.theme {
  @include theme () {
    .btn-container {
      .btn-primary {
        background-color: getThemeValue("color__copy--dark");
        color: getThemeValue("color__contrast");
        border: 2px solid getThemeValue("color__copy--dark");
        .icon {
          svg, polyline, path {
            stroke: getThemeValue("color__contrast");
            &:active,
            &:hover {
              stroke: getThemeValue("color__contrast");
            }
          }
        } 
        &:disabled,
        &.disabled {
          background-color: getThemeValue("color__grey");
          border: 2px solid getThemeValue("color__grey");
          color: getThemeValue("color__copy--dark");

          .icon {
            svg, polyline, path {
              stroke: getThemeValue("color__copy--dark");
              &:active,
              &:hover {
                stroke: getThemeValue("color__copy--dark");
              }
            }
          } 

          &:active,
          &:hover {
            background-color: getThemeValue("color__grey");
            border: 2px solid getThemeValue("color__grey");
            color: getThemeValue("color__copy--dark");

            .icon {
              svg, polyline, path {
                stroke: getThemeValue("color__copy--dark");
                &:active,
                &:hover {
                  stroke: getThemeValue("color__copy--dark");
                }
              }
            } 
          }
        }
        &:active,
        &:hover {
          background-color: getThemeValue("color__brand--medium");
          border: 2px solid getThemeValue("color__brand--medium");
          color: getThemeValue("color__button--hover");

          .icon {
            svg, polyline, path {
              stroke: getThemeValue("color__button--hover");
              &:active,
              &:hover {
                stroke: getThemeValue("color__button--hover");
              }
            }
          } 
        }
      }
      .btn-secondary {
        background-color: transparent;
        color: getThemeValue("color__copy--dark");
        border: 2px solid getThemeValue("color__copy--dark");
        .icon {
            svg, polyline, path {
            stroke: getThemeValue("color__copy--dark");
            &:active,
            &:hover {
              stroke: getThemeValue("color__brand--medium");
            }
          }
        }
        &:disabled,
        &.disabled {
          background-color: transparent;
          border: 2px solid getThemeValue("color__grey");
          color: getThemeValue("color__grey");

          .icon {
            svg, polyline, path {
              stroke: getThemeValue("color__grey");
              &:active,
              &:hover {
                stroke: getThemeValue("color__grey");
              }
            }
          }

          &:active,
          &:hover {
            background-color: transparent;
            border: 2px solid getThemeValue("color__grey");
            color: getThemeValue("color__grey");

            .icon {
              svg, polyline, path {
                stroke: getThemeValue("color__grey");
                &:active,
                &:hover {
                  stroke: getThemeValue("color__grey");
                }
              }
            }
          }
        }
        &:active,
        &:hover {
          background-color: transparent;
          border: 2px solid getThemeValue("color__brand--medium");
          color: getThemeValue("color__brand--medium");

          .icon {
            svg, polyline, path {
              stroke: getThemeValue("color__brand--medium");
              &:active,
              &:hover {
                stroke: getThemeValue("color__brand--medium");
              }
            }
          }
        }
      }
      .btn-tertiary {
        background-color: transparent;
        color: getThemeValue("color__copy--dark");
        border: none;
        .icon {
          svg, polyline, path {
            stroke: getThemeValue("color__copy--dark");
            &:active,
            &:hover {
              stroke: getThemeValue("color__brand--medium");
            }
          }
        }
        &:disabled,
        &.disabled {
          background-color: transparent;
          border: none;
          color: getThemeValue("color__grey");

          .icon {
            svg, polyline, path {
              stroke: getThemeValue("color__grey");
              &:active,
              &:hover {
                stroke: getThemeValue("color__grey");
              }
            }
          }

          &:active,
          &:hover {
            background-color: transparent;
            border: none;
            color: getThemeValue("color__grey");

            .icon {
              svg, polyline, path {
                stroke: getThemeValue("color__grey");
                &:active,
                &:hover {
                  stroke: getThemeValue("color__grey");
                }
              }
            }
          }
        }

        &:active,
        &:hover {
          background-color: getThemeValue("color__copy--dark");
          border: none;
          color: getThemeValue("color__contrast");

          .icon {
            svg, polyline, path {
              stroke: getThemeValue("color__contrast");
              &:active,
              &:hover {
                stroke: getThemeValue("color__contrast");
              }
            }
          }
        }
      }
    }
  }
}


////////////////////////
/// BUTTON CONTAINER ///
////////////////////////

.btn-container {
  width: 100%;
  display: flex;
  max-width: 400px;
  align-items: center;
  justify-content: center;

  .btn-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-text {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .btn-text__icon {
      // When icon and button are present
      justify-content: flex-start !important;
    }
  }

  // Button Sizes
  .standard {
    .btn-inner {
      gap: 20px;
    }
  }

  .small {
    .btn-inner {
      gap: 12px;
    }
  }

  &:last-child {
    margin-right: 0; // Remove margin from last child button
  }
}

//////////////////////
/// PRIMARY BUTTON ///
//////////////////////

.btn-primary {
  @include transition();
  font-family: $font-family__primary;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  &:disabled,
  &.disabled {
    &:active,
    &:hover {
      cursor: default;
    }
  }

  &.standard {
    width: 100%;
    height: 3rem;
    font-size: 1.25rem;
    line-height: 1rem;
    padding: 8px 16px;
    gap: 8px;

    @include breakpoint("xsmall+") {
      max-width: 28rem;
    }

    @include breakpoint("medium+") {
      max-width: 40rem;
      min-width: 5rem;
    }

    @include breakpoint("xlarge+") {
      max-width: 72.5rem;
    }
  }

  &.small {
    width: fit-content;
    min-width: 94px;
    height: 32px;
    font-size: 12px;
    line-height: 1.25rem;
    padding: 8px 16px;
    gap: 6px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }

  .icon {
    svg, polyline, path {
      @include transition();

      &:active,
      &:hover {
        @include transition();
      }
    }
  } 
}

////////////////////////
/// SECONDARY BUTTON ///
////////////////////////

.btn-secondary {
  @include transition();
  font-family: $font-family__primary;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  &:disabled,
  &.disabled {
    &:active,
    &:hover {
      cursor: default;
    }
  }

  &.standard {
    width: 100%;
    height: 3rem;
    font-size: 1.25rem;
    line-height: 1rem;
    padding: 8px 16px;
    gap: 8px;
    @include breakpoint("xsmall+") {
      max-width: 28rem;
    }

    @include breakpoint("medium+") {
      max-width: 40rem;
      min-width: 5rem;
    }

    @include breakpoint("xlarge+") {
      max-width: 72.5rem;
    }
  }

  &.small {
    width: fit-content;
    min-width: 94px;
    height: 32px;
    font-size: 12px;
    line-height: 1.25rem;
    padding: 8px 16px;
    gap: 6px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }

  .icon {
    svg, polyline, path {
      @include transition();

      &:active,
      &:hover {
        @include transition();
      }
    }
  } 
}

///////////////////////
/// TERTIARY BUTTON ///
///////////////////////

.btn-tertiary {
  @include transition();
  font-family: $font-family__primary;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  &:disabled,
  &.disabled {
    &:active,
    &:hover {
      cursor: default;
    }
  }

  &.standard {
    width: fit-content;
    min-width: 124px;
    height: 3rem;
    font-size: 1.25rem;
    line-height: 1rem;
    padding: 8px 16px;
    gap: 8px;
    @include breakpoint("xsmall+") {
      max-width: 28rem;
    }

    @include breakpoint("medium+") {
      max-width: 40rem;
      min-width: 5rem;
    }

    @include breakpoint("xlarge+") {
      max-width: 72.5rem;
    }
  }

  &.small {
    width: fit-content;
    min-width: 94px;
    height: 32px;
    font-size: 12px;
    line-height: 1.25rem;
    padding: 8px 16px;
    gap: 6px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }

  .icon {
    svg, polyline, path {
      @include transition();

      &:active,
      &:hover {
        @include transition();
      }
    }
  } 
}

////////////////////////////
/// custom input classes ///
////////////////////////////

.__more-actions-secondary {
  margin-top: 0.625rem;
}
