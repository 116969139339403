.attendee-initials {
  width: $attendee-initials-height;
  height: $attendee-initials-height;
  border-radius: 0.25rem;
  display: inline-block;
  position: relative;
  flex-shrink: 0;

  &__text {
    font-weight: bold;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  &__superscript {
    position: absolute;
    right: -18%;
    top: -18%;
  }
}
