/////////////
/// THEME ///
/////////////
.theme {
  @include theme() {
    .controls-container {
      background-color: getThemeValue("color__background--shadow");
      
      .video-controls-container {
        &--time {
          color: getThemeValue("color__copy--label");
        }
      }
    }
  }
}

////////////////////
/// VIDEO VIEWER ///
////////////////////
@include breakpoint("xsmall+") {
  .video-screen {
    width: 60vw;
    height: 60vh;
    margin: 12vh auto 0;
    &.--full {
      width: 100vw;
      height: 89.75vh;
      z-index: map-get($z-indexes, "video");
      position: absolute;
      margin: 0;
      -moz-transition: height 0.75s ease;
      -webkit-transition: height 0.75s ease;
      -o-transition: height 0.75s ease;
      transition: height 0.75s ease;
    }
    &.--preview {
      width: 80%;
      height: 350px;
      margin-top: 1rem;
    }
    @media screen and (max-height: 700px) {
      &.--full {
        height: 85.5vh;
      }
    }
  }
  .video-player-container {
    width: 100%;
    height: 100%;
    // margin: 12vh auto 0;
    &.--student {
      pointer-events: none;
    }
  }
  .controls-container {
    display: flex;
    flex-direction: column;
    .video-controls-container {
      display: flex;
      width: 100%;
      &__video-progress {
        display: flex;
        align-items: center;
        width: 80%;
      }
      &__progress-container {
        display: inline-block;
        width: 70%;
      }
      &__progress-container {
        display: inline-block;
        width: 70%;
      }
      &--time {
        display: inline-block;
        margin: 0 0.6rem;
        width: 10%;
      }
    }
    .volume-controls-container {
      width: 100%;
      display: flex;
      &__volume-progress {
        align-items: center;
        display: flex;
        width: 35%;
      }
    }
    .--progress-container {
      display: inline-block;
      width: 70%;
    }
    .--video-progress {
      display: inline-block;
      vertical-align: middle;
      width: 80%;
      margin-bottom: 1rem;
    }
    .--volume-progress {
      margin-bottom: 1rem;
      vertical-align: middle;
      display: inline-block;
      width: 35%;
    }
    .--time {
      display: inline-block;
      margin: 0 0.6rem;
      width: 10%;
    }
  }
}

@include breakpoint("xlarge+") {
  .video-screen {
    &.--full {
      height: 95.5vh;
    }
    @media screen and (max-height: 700px) {
      &.--full {
        height: 91.5vh;
      }
    }
  }
  .controls-container {
    flex-direction: row;
    .volume-controls-container {
      width: 60%;
      text-align: end;
    }
    .video-controls-container {
      &__video-progress {
        width: 72%;
      }
      &__progress-container {
        width: 63%;
      }
      &--time {
        min-width: 40px;
      }
      .--video-progress {
        width: 72%;
      }
      .--time {
        min-width: 40px;
      }
      .--progress-container {
        width: 63%;
      }
    }
    &--time {
      min-width: 40px;
    }
  }
}
